.menu-horizontal {
  width: 95%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  text-align: center;
}
@include media(">=tablet") {
  .menu-horizontal {
    //padding-top: 2rem;
    //padding-bottom: 2rem;
    //max-width: 1159px;
    

  }  
}
