.store-social {
  .store-social__header {
    text-align: center;
  }

  .store-social__icons {
    text-align: center;

    img {
      display: inline-block;
      margin: 0px 15px 15px 15px;
      width: 50px;
    }

  }
}
