.banner-roadside {
  background-color: $orange;

  .banner-roadside__image {
    display: flex;
    flex-direction: column;
    align-items: center;  
    padding-top: 43px;
    padding-bottom: 42px;
    img {
      &:first-child {      
        margin-bottom: 21px;
        width: 60%;
      }

      &:last-child {      
        width: 60%;
      }
    }
  }

  .banner-roadside__copy {
    display: none;
  }
}

@include media(">=tablet"){
  .banner-roadside {
    .banner-roadside__image {
      padding-top: 22px;
      padding-bottom: 22px;
      flex-direction: row-reverse;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      img {
        &:first-child {   
           width: 50%;
           margin-left: 1rem;
        }
  
        &:last-child {
          width: 50%;
        }
      }
    }

    .banner-roadside__copy {
      display: block;
      background-color: #000000;
      color: #ffffff;
      padding: 114px 0 114px 0;

      p {
        width: 70%;
        margin: 0 auto;
        line-height: 50px;

        &:first-child {
          margin-bottom: 1rem;
        }

        &:last-child {
          text-align: right;
        }
      }

      .special-text {
        color: $orange;
        text-transform: uppercase;
      }
    }
  } 
}
