.menu-item-small {
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  a {
    text-decoration: none;
    color: $dark-brown;
    img {
      margin-bottom: 1rem;
      max-width: 8.5rem/* 100/16 */;
    }
  }
  .menu-item-small__header {
    text-align: center;
    .accent-small {
      display: block;
      margin-bottom: -0.85rem;
      margin-top: -0.8125rem/* 29/16 */;
    }
  }
}

@include media(">=tablet") {
  .menu-item-small {
    a {
      img {
        margin: 0 auto;
        margin-bottom: 2rem;
        display: block;
        max-width: 8.5rem/* 200/16 */;
      }
    }
    .menu-item-small__header {
      .accent-small {       
        margin-top: -1.8125rem/* 29/16 */;
      }
    }
  }
}