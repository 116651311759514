@import "node_modules/include-media/dist/include-media";
$breakpoints: (phone: 375px, tablet: 768px, desktop: 1200px);

main {
  min-width: 375px;
  max-width: 1400px;
  margin: 0 auto;
}

@include media("<=tablet"){
  .hide-mobile {
    display: none;
  }
}
