.post-teaser {
  text-align: center;
  
  .post-teaser__wrapper {
    margin-bottom: 2rem;
    display: inline-block;
    .blog-img {
      width: 250px;
      height: auto;
    }
    .post-teaser__card {
      border: 0.25rem solid;
      width: 16.875rem;
      height: 10.9375rem;
      background-color: #ffffff;
      margin-top: -9rem;
      position: relative;
      margin-left: 8rem;
      padding-left: 1.125rem;
      padding-top: 1rem;
      text-align: left;
      border-color: $orange;
      display: flex;
      flex-direction: column;
      .post-teaser__card-category {
        a {
          width: 100%;
          font-size: 0.625rem;
          color: $orange;
          text-transform: uppercase;
        }
      }
      a {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.938rem;
        color: #f26c31;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }
}

@include media(">=tablet") {
  .post-teaser {
    flex-basis: 25%;
  }
}

@include media(">=953px", "<desktop") {
  .post-teaser {
    .blog-img {
      width: 7.9375rem;
    }
    .post-teaser__card {
      width: 11.875rem;
      height: 11.9375rem;
    }
  }
}