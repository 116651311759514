.social-horizontal {
  margin-top: 2rem;
  .h1 {
    text-align: center;    
    margin-bottom: 25px;
  }

  .horizontal-scroller__item {
    &:first-of-type {
      margin-left: 30px;
    }
    img {
      margin-right: 30px;
    }
  }
}

@include media(">=tablet"){
  .social-horizontal {
    .horizontal-scroller{
      

      .horizontal-scroller__item {
        &:first-of-type {
          margin-left: 1.5%;
        }
        
        &:last-of-type {
          img {
            margin-right: 0px;
          }
        }

        img {        
          margin-bottom: 30px;
          width: 16rem;
        }
      }
    }
  }
}

/*@include media(">=desktop"){
  .social-horizontal {
    .horizontal-scroller{
      justify-content: space-evenly;
      .horizontal-scroller__item {
        flex-basis: 15%;
        &:first-of-type {
          margin-left: 10px;
        }
        img {        
          margin-right: 0px;
        }
      }
    }
  }
}*/
