.mug-club-form {
    padding: 1.563rem /* 25/16 */;
    margin-bottom: 1.563rem;
    background-color: $orange;
  
    h1 {
      text-align: center;
      color: #ffffff;
    }

    .header__reasons {
      display: flex;
      text-align: center;
      justify-content: space-around;
      max-width: 400px;
      margin: 0 auto;
  
      img, p {
        width: 7.5rem /* 120/16 */;
      }
  
      p {
        color: #ffffff;
      }
    }
  
    .aw-form{
      width: 325px;
      margin: 0 auto;
      .section-label {
        color: #ffffff;
        font-size: 20px;
        margin-left: 1.563REM;
        margin-bottom: 10PX;
      }
    }
  
    .birthday-field {
      display: flex;
    }
  
    .aw-field {
      margin: 0 auto;
      margin-bottom: 1.25rem /* 20/16 */;
      max-width: 23.563rem;
  
      input, select,  {
        border-color: $dark-brown;
        background-color: $orange;
        color: #ffffff;
        font-size: 1rem;
      }
  
      label, ::placeholder {
        color: #ffffff;
        font-size: 1rem;
      }
  
      &.checkbox {
        &.legal {
          text-align: center;
  
          a {
            color: #ffffff;
            text-decoration: underline;
          }
        }
      }
    }
  
    input[type="button"] {
      margin: 0 auto;
      display: block;
    }
  }
  
  @include media(">=tablet") {
    .mug-club-form {
  
      
      

          .header__reasons {
              display: flex;            
              justify-content: space-between;
              width: 100%;


              div {
                &:first-child {
                    margin-left: -4rem;
                    margin-right: 2rem;
                }
              }
              img,p {
                width: 14rem /* 256/16 */;

                
                
              }

              p {
                  margin-top: 1rem;
                  font-size: 1.5rem;
              }
          
              
          }
      
  
  
      .aw-form{
        width: unset;
        margin: unset;
        margin-right: 2rem;
      }
  }
  }
  