.location-finder {
  
  .location-finder__header {
    background-color: $orange;
    height: 2.813rem /* 77/16 */;
    text-align: center;

    h1 {
      color: #FFFFFF;
      line-height: 2.813rem /* 77/16 */;
    }
  }

  .location-finder__search {
    margin: 0 auto;
    margin-top: 1.875rem /* 30/16 */;
    width: 375px;


    .aw-field.search {
      margin: 0 auto;
      input {
        border-color: $dark-brown;
        color: $light-brown;
        font-size: 1rem;
      }

      label, ::placeholder {
        color: $light-brown;
        font-size: 1rem;
      }

      i {
        color: #ffffff;
        background-color: $orange;
      }
    }

    .location-finder__filter {
      width: 18.875rem /* 302/16 */;
      margin: 0 auto;
      margin-top: 1.563rem /* 25/16 */;
      background-color: #ffffff;
      .location-finder__filter-button {
        border-bottom: solid 1px;
        border-bottom-color: $light-brown;
        margin-bottom: 16px /* 1*16 */;
        
        i, input {
          color: $light-brown;
          margin-bottom: 0.5rem /* 8/16 */;
        }

        input {
          background-color: transparent;
        }

      }


      .location-finder__filter-options {
        width: 18.875rem /* 302/16 */;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .aw-field {
          flex-basis: 50%;
          color: $light-brown;
        }
        
        &[data-visible="false"] {
          animation: options-hide 250ms ease-in-out forwards;
        }
        &[data-visible="true"] {
          animation: options-reveal 250ms ease-in-out forwards;
        }
      }

    }

    .aw-field.switch {
      margin-top: 2.5rem /* 40/16 */;
      display: flex;
      justify-content: center;

      label{
        width: 9.375rem /* 150/16 */;
      }
    }
  }
  .location-finder__map {
    text-align: center;
    display:none;
    &[data-visible="false"] {
      display: none;
    }
    &[data-visible="true"] {
      display: block;
    }
  }
  .location-finder__results {
    width: 375px;
    margin: 0 auto;    
  }
}


@include media(">=tablet") {
  .location-finder {
    .location-finder__header {
      margin-top: -3rem;
    }
    .flex-row {
      flex-direction: row-reverse;
    }

    .location-finder__search {
    .aw-field.switch {
      display:none;
    }
  }

  .location-finder__map {
    display: block;
    iframe {
      width: 90%;
      height: 500px;
      margin: 0;
      margin-top: 2rem;          
    }

    &[data-visible="false"] {
      display: block;
    }
    &[data-visible="true"] {
      display: block;
    }
  }
  .location-finder__results {
    height: 400px;
    overflow: scroll;  
  }
}
}

@keyframes options-reveal {
  from {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  to {
    height: 68px;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes options-hide {
  from {
    height: 68px;
    opacity: 1;
    visibility: visible;
  }
  to {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}