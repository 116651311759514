// Global Settings
@import 'reset';
@import 'global-breakpoints';
@import 'global-flex-row';
@import 'global-colors';
@import 'global-typography';
@import 'global-icons';
@import 'global-inputs';
@import 'global-buttons';
@import 'global-images';
@import 'global-horizontal-scroller.scss';
@import 'navigation';
@import 'tout';
@import 'header-carousel';
@import 'header-static';
@import 'location-banner';
@import 'legal';
@import 'menu-horizontal';
@import 'menu-header';
@import 'menu-item';
@import 'menu-item-small';
@import 'roadside';
@import 'social-horizontal';
@import 'inside-the-mug';
@import 'post-teaser';
@import 'footer-ankle';
@import 'form-mug-club';
@import 'sweepstakes';
@import 'form-location-finder';
@import 'form-contact';
@import 'location-card';
@import 'store-current-deal';
@import 'store-header';
@import 'store-rooty';
@import 'store-social';
@import 'location-result-card';
@import 'blog-card';
@import 'blog-category-horizontal';
@import 'blog-category-item';
@import 'blog-latest';
@import 'blog-post-list';
@import 'header-blog-category';
@import 'blog-category-page';
@import 'blog-post';
@import 'navigation-pagination';
@import 'footer';



// @import "menu";
main {
  overflow: hidden;
}

/* Some Extra Classes */
.sg-header {
  width: 100%;
  background-color: pink;
  color: blue;
  font-size: 1.875rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.sg-buttons
{
  .aw-field, input[type="button"] {
  display: block;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
}
