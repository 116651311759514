.banner-location-selector {
  text-align: center;
  mask-image: url(../assets/nav-mask.svg);
  mask-size: 375px;
  mask-repeat: repeat-x;
  mask-mode: alpha;
  mask-position: -1px;
  min-height: 2.25rem;
  z-index: 3;
  position: relative;
  margin-bottom: -10px !important;
  color: #ffffff;

  &[data-location-selected="false"] {
    background-color: $dark-brown
  }

  &[data-location-selected="true"] {
    background-image: url(../assets/bg-nav.svg);
    background-size: auto;
  }

  .banner-location-select {
    align-items: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: .5rem;
    padding-right: .5rem;

    &[data-visible="false"] {
      display: none;
    }
  
    &[data-visible="true"] {
      display: block;
    }

    .banner-location-select__choose {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      margin: 0 auto;
      margin-top: 5px;
      i {
        margin-left: .5rem;
        color:#ffffff;
      }
    }
  }

  .banner-location-selected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: .5rem;
    padding-right: .5rem;

    &[data-visible="false"] {
      visibility: hidden;
      height: 0;      
    }
  
    &[data-visible="true"] {
      visibility: visible;
      height: 40px;
    }


    .banner-location-selected__map-link,
    .banner-location-selected__store-name,
    .banner-location-selected__info-link
     {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      margin-top: 5px;
    }

    .banner-location-selected__map-link {
      i {
        margin-right: .5rem;
      }
    }

    .banner-location-selected__info-link {
      i {
        margin-left: .5rem;
        color: #ffffff;
      }
    }
  }
}
.location-banner-info-card {
  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 1400px;

  .location-banner-info-card__change {
    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.banner-location-select__search {
  overflow-y: hidden;
	max-height: 186px; /* approximate max height */
	transition-property: all;
	transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  
  position: absolute;
  top: 5rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  text-align: center;
  padding-top: 2.5rem /* 40/16 */;
  padding-bottom: 1.5rem;
  max-width: 1400px;
  box-shadow: -2px 24px 24px -19px #111;

  &[data-visible="false"] {
    height: 0px;
    padding: 0px;
  }

  &[data-visible="true"] {
    height: 186px;
  }
  
  .aw-field {
    margin: 0 auto;
  }
  span {
    display: block;
  }
}

.banner-location-selected__info-card {
  overflow-y: hidden;
	max-height: 350px; /* approximate max height */
	transition-property: all;
	transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  box-shadow: -2px 24px 24px -19px #111;

  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  max-width: 1400px;

  .location-banner-info-card {
    margin-top: 0;
  }

  &[data-visible="false"] {    
    height: 0px;
    margin: 0px;

    .location-banner-info-card {
      visibility: hidden;
      height: 0;      
    }
  }

  &[data-visible="true"] {
    height: 350px;
    margin: 0px;

    .location-banner-info-card {
      visibility: visible;
      height: unset;
    }
  }
}

.banner-location-select__results {
  overflow-y: hidden;
	max-height: 230px; /* approximate max height */
	transition-property: all;
	transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  box-shadow: -2px 24px 24px -19px #111;

  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  padding-top: 3.5rem;
  max-width: 1400px;
  &[data-visible="false"] {
    height: 0;
  }

  &[data-visible="true"] {
    height: 230px;
  }
}



@include media(">=tablet"){
  .banner-location-selector {
    mask-size: 375.4px;        
    min-height: 2.813rem /* 45/16 */;
    margin-top: -43px;

   

    .banner-location-selected {
      justify-content: center;

     .banner-location-selected__map-link,
     .banner-location-selected__store-name,
     .banner-location-selected__info-link
     {
       margin-top: 10px;
     }

     .banner-location-selected__store-name{
         margin-left: 2rem;
         margin-right: 2rem;
      }   
    }
  }



    .location-banner-info-card {

    top: 4rem;

  }
  .banner-location-select__search {    
    top: 6rem;    
  }
}

