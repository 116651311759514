.blog-post-list {
    
    h1 {
        width: 85%;
        margin: 0 auto;
    }

    .blog-card {
        p {
            display: none;
        }
    }

    a:last-child {
        display: block;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 1.563rem /* 25/16 */;
    }
}