/* https://codepen.io/segg/pen/oPbVeP */
.aw-pagination {
	margin: 2rem auto 1rem;
	text-align: center;
	flex-flow: row wrap;
	justify-content: center;
	display: flex;
	font-size: 1.6rem;
}

	.aw-pagination > a {
		background-color: $orange;
		color: white;
		height: 2.5rem;
		line-height: 2.5rem;
		outline: none;
		text-decoration: none;
		transition: all .2s;
		width: 2.5rem;
	}

	.aw-pagination > .first + .current {
		margin-left: .4rem;
	}

	.aw-pagination > .current + .last {
		margin-left: .4rem;
	}

	.aw-pagination > a.Icon {
		margin: 0 .4rem;
		stroke: currentColor;
	}

	.aw-pagination > a:hover,
	.aw-pagination > a:focus,
	.aw-pagination > a:active,
	.aw-pagination > form > input:focus,
	.aw-pagination > form > input:hover {
		background-color: $light-brown;
		box-shadow: 0 .3rem .3rem -.2rem $orange;
	}

			.aw-pagination > form > input {
				-moz-appearance: textfield;
				background-color: $light-brown;
				border: none;
				color: inherit;
				height: 2.5rem;
				line-height: 2.5;
				outline: none;
				text-align: inherit;
				width: 2.5rem;
				color: white;
			}

				.aw-pagination > form > input::-webkit-inner-spin-button,
				.aw-pagination > form > input::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

			.aw-pagination > form > label {
				position: absolute;
				right: 110vw;
			}

	.aw-pagination > small {
		margin: 1rem auto;
		min-width: 100%;
	}
