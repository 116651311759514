.owl-carousel .owl-wrapper-outer{
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;  /* added this */
}

.owl-carousel {
    margin-bottom: -2rem;

    .owl-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      position: absolute;
      top: 70px;
      color: white;
      font-size: 3rem;   
      
      [class*=owl-]:hover{
        background:none !important;
      }
    }
    .owl-dots {
      display: none;      
    }      
}


@include media(">=550px", "<tablet") {
  .owl-carousel {     
    .owl-dots {
      top: 36%;      
    }    
  }
}

@include media(">=tablet") {
    .owl-carousel {  
      .owl-nav {        
        padding-left: 2rem;
        padding-right: 2rem;
        top: 180px;
        font-size: 5rem;
      }
      .owl-dots {
        display: block;
        position: absolute;
        width: 100%;
        top: 470px;
        //padding-left: 25rem;        
      }      
    }
}