.header {
    .header-wrapper {
      position: relative;
      margin-bottom: 2.313rem/* 37/16 */;
      img {
        width: 100%;
        height: 250px;
        margin: 0 auto;
        object-fit: cover;
      }
      .header-banner {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 auto;
        border: 0.25rem/* 4/16 */
        solid;
        padding: 1rem 0 1rem 0;
        width: 80%;
        text-align: center;
        background-color: #ffffff;
        margin-top: -5rem;
        
        
        position: relative;
        &.orange {
          border-color: $orange;
        }
        &.light-brown {
          border-color: $light-brown;
        }
        &.dark-brown {
          border-color: $dark-brown;
        }
        &.light-blue {
          border-color: $light-blue;
        }
        &.dark-blue {
          border-color: $dark-blue;
        }
        .header-banner-header {
          padding: 0px 0px 0px 0px;
          &.flair {
            &:before {
              content: ' ';
              background-image: url(../assets/icon-three-left.svg);
              background-size: 33px 33px;
              background-repeat: no-repeat;
              height: 33px;
              width: 33px;
              display: inline-block;
              position: absolute;
              top: 10%;
              left: 17%;
            }
            &:after {
              content: ' ';
              background-image: url(../assets/icon-three-right.svg);
              background-size: 33px 33px;
              background-repeat: no-repeat;
              height: 33px;
              width: 33px;
              display: inline-block;
              position: absolute;
              top: 10%;
              left: 75%;
            }
          }
          .header-banner-header__accent {
            min-height: 1.25rem;
            margin-bottom: -2rem;
            &:empty {
              display: none;
            }
          }
          .header-banner-header__main {
            margin-top: 1rem;
            line-height: 2rem/* 24/16 */;
            margin-bottom: .5rem;
          }
        }
        .header-banner__body {
          display:block;
          width: 90%;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
      }
      
    }    
  }
  
  @include media(">=tablet") {
    .header {
      margin-bottom: 1.5%;
      .header-wrapper {
        //margin-bottom: 3.313rem/* 37/16 */;
        img {
          height: 500px;
        }
        .header-banner {
            margin: 0 auto;            
            width: 30%;
            height: 50%;                                  
            position: absolute;            
            top: 25%;
            left: 5rem;          
        }     
      }
     
    }
  }