.blog-card {    
    width: 100%;
    margin: 0 auto;
    background-color: white;
    margin-bottom: 2rem;
    
    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }

    .blog-card__body {
        margin: 0 auto;
        width: 90%;        

        span, a:last-child {
            display: inline-block;
            width: 49%;
        }

        span {
            font-size: 0.625rem;
            text-transform: uppercase;
        }
        a:first-child{
            font-size: 0.625rem;
            color: $orange;
            text-transform: uppercase;
        }
        a:last-child {
            text-align: right;
            font-size: 0.75rem;
            line-height: 0.938rem;
            color: #f26c31;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}

