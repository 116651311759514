.inside-the-mug {
  position: relative;
  .h1 {
    text-align: center;
    margin-top: 45px;
    margin-bottom: 25px;
  }

  a {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 1.563rem /* 25/16 */;
  }
}

@include media(">=tablet"){
  .inside-the-mug { 
  .inside-the-mug__posts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
}
