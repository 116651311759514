.aw-icon {
    text-decoration: inherit;
    font-size: 1rem;

    &.fa-search {
      color: #fff;
      display: inline-block;
      border-radius: 4rem /* 64/16 */;
      box-shadow: 0 0 0.125rem /* 2/16 */ #888;
      padding: 0.4rem 0.4rem;
      background-color: #000;
    }

    &.fa-angle-down {
      text-decoration: inherit;
      color: #000;
      font-size: 1rem;
    }

    &.fa-crosshairs {
      color: #fff;
      display: inline-block;
      border-radius: 4rem /* 64/16 */;
      box-shadow: 0 0 0.125rem /* 2/16 */ #888;
      padding: 0.4rem 0.4rem;
      background-color: #000;
    }
}
