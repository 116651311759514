.location-result-card {
  background-color: $orange;
  font-size: 0.625rem /* 10/16 */;
  color: #ffffff;
  padding: 0.625rem 1.925rem 0.625rem 1.925rem;
  margin-left: 15px;
  margin-top: 1.688rem /* 27/16 */;
  margin-bottom: 1.688rem;
  border-radius: 5px;
  background-image: url(../assets/bg-secondary-tout.svg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;


  .location-result-card__distance {
    margin-bottom: 1.625rem /* 26/16 */;
    i {
      margin-right: 0.5rem;
    }
  }
  .location-result-card__store-name {

  }
  .location-result-card__address {
    text-align: center;
  }
}
