section.form-contact {
    margin: 0 auto;    
    text-align: center;
    margin-top: 1rem;

    .form-contact__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    
    .aw-field, input[type='button'] {
        margin-top: 1rem;
    }    
}