$white: #ffffff;
$light-brown: #963821;
$dark-brown: #4f2c1d;
$orange: #f26c31;
$light-blue: #56c9e8;
$dark-blue: #006e9f;
$grey: #F4F4F4;

body {
  background-color: whitesmoke;
  margin-top: -5px;
}

main {
  background-color: #ffffff;
}

/*Additional Styles for Debugging*/
div.swatch {
  display: inline-block;
  width: 6.25rem /* 100/16 */;
  height: 6.25rem /* 100/16 */;
  border-radius: 1.563rem /* 25/16 */;
  border-color: black;
  border-width: 0.188rem /* 3/16 */;
  border-style: solid;

  &.white {
    background-color: $white;
  }
  &.light-brown {
    background-color: $light-brown;
  }
  &.dark-brown {
    background-color: $dark-brown;
  }
  &.orange {
    background-color: $orange;
  }
  &.light-blue {
    background-color: $light-blue;
  }
  &.dark-blue {
    background-color: $dark-blue;
  }
}
