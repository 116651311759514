.region-footer {
  background-image: url(../assets/bg-footer.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.aw-footer {
  font-family: "Century Gothic Bold";
  color: #ffffff;
  //margin-top: 1rem /* -25/16 */;

  a {
    color: #ffffff;
    display: block;
    margin-top: 1.5rem /* 24/16 */;
    cursor: pointer;


    &:hover {
      color: #ffffff;
      text-decoration: underline;

    }
  }

  .site-links, .external-links {
    display: flex;
    justify-content: space-evenly;
  }

  .external-links {
    font-size: 0.625rem /* 10/16 */;
  }

  .social-links {
    display: flex;
    justify-content: center;
    a {
      display: inline-block;
      margin-right: 1.5rem /* 24/16 */;

      img {
        height: 2.25rem /* 36/16 */;
      }
    }
  }

  .copyright {
    margin: 0 auto;
    font-size: 0.625rem;
    text-align: center;
    padding-bottom: 1rem;
    margin-top: 1rem;
    width: 90%;
  }
}

@include media(">=tablet") {
  .aw-footer {    
    a {
      display: inline-block;      
      margin-left: .5rem;
    }
  
    .site-links, .external-links {
      justify-content: center;
    }    
  }
}