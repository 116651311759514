  .aw-nav {
    display: flex;
    justify-content: space-between;
    font-family: "Century Gothic Painted";
    margin: 0.313rem /* 5/16 */;
    padding-top: 0.5rem;


    .aw-nav__logo {
      .logo-desktop {
        display: none;
      }
      .logo-mobile {
        display: block;
      }
    }
    .aw-nav__links{
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        margin-left: 1.625rem /* 26/16 */;
        color: $light-brown;

        &.aw-btn-primary {
          color: #ffffff;

          &[data-online-ordering="false"] {
            visibility: hidden;
            width: 0px;
            margin-right: -50px;
          }

          &[data-online-ordering="true"] {
            visibility: visible;
            width: unset;
            margin-right: 0;
          }
        }
      }
    }
    img {
              display: block;
      width: 4.25rem /* 68/16 */;
      z-index: 5;
    }
  }

  @include media(">=tablet"){
    .aw-nav {
      display: flex;
      justify-content: space-between;
      font-family: "Century Gothic Painted";
      margin: 0.313rem /* 5/16 */;


      .aw-nav__logo {
        z-index: 4;

        .logo-desktop {
          display: block;
        }
        .logo-mobile {
          display: none;
        }
      }

      .aw-nav__links{
        display: flex;
        align-items: center;
        justify-content: center;
        a {
              margin-top: -40px;
          margin-left: 1.625rem /* 26/16 */;
          color: $light-brown;

          &.aw-btn-primary {
            color: #ffffff;
          }
        }
      }

      img {
        width: 9.938rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
      }
    }

  }
