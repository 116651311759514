.sweepstake, .contact-us {
  margin-top: 2rem;

    .sweepstake__image, .contact-us__image {
      img {
        display: block;
        width: 100%;        
    }
  }

  .sweepstake__header, .contact-us__header {
    display: none;
  }
  .aw-form {
    .aw-field {
      margin: 0 auto;
      margin-bottom: 1.25rem /* 20/16 */;
      max-width: 23.563rem;
    }


    input[type="button"] {
      margin: 0 auto;
      display: block;
    }
  }
}

@include media (">=tablet") {
  .sweepstake, .contact-us {
    .sweepstake__header, .contact-us__header {
      display: block;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
