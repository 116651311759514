section {
    &.flex-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 1rem;

        .flex-10, .flex-20, .flex-30, .flex-40, .flex-50, .flex-60, .flex-70, .flex-80, .flex-90, .flex-100,  {
            flex-basis: 100%;
        }

        &.no-margin {
            margin-bottom: 0rem;
        }
    }
}

@include media(">=tablet"){
    section {
        &.flex-row {         
            .flex-10 {
                flex-basis: 8%;
            }
    
            .flex-20 {
                flex-basis: 18%;
            }
    
            .flex-30 {
                flex-basis: 28%;
            }
    
            .flex-40 {
                flex-basis: 38%;
            }
    
            .flex-50 {
                flex-basis: 48%;
            }
    
            .flex-60 {
                flex-basis: 58%;
            }
    
            .flex-70 {
                flex-basis: 68%;
            }        
    
            .flex-80 {
                flex-basis: 78%;
            }
    
            .flex-90 {
                flex-basis: 88%;
            }
    
            .flex-100 {
                flex-basis: 98%;
            }
        }
    }
}
