article.legal {
    width: 90%;
    min-height: 80vh;
    margin: 0 auto;
    margin-top: 1rem;
    h1 {
        text-align: center;        
    }
    h2 {
        margin-top: .5rem;
    }
    p {
        margin: 0 auto;
    }
}

@include media(">=tablet") {
    article.legal {
        width: 60%;
        h1 {
            text-align: center;
        }
    }
}
