.tout {
  .tout-wrapper {
    position: relative;
    margin-bottom: 2.313rem/* 37/16 */;
    img {
      width: 90%;
      height: 250px;
      margin: 0 auto;
      object-fit: cover;
    }
    .tout-banner {             
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 auto;
      border: 0.25rem/* 4/16 */
      solid;
      padding-top: 1rem;
      //padding-bottom: 1rem;
      width: 80%;
      text-align: center;
      background-color: #ffffff;
      margin-top: -16%;
      
      
      position: relative;
      &.orange {
        border-color: $orange;
      }
      &.light-brown {
        border-color: $light-brown;
      }
      &.dark-brown {
        border-color: $dark-brown;
      }
      &.light-blue {
        border-color: $light-blue;
      }
      &.dark-blue {
        border-color: $dark-blue;
      }
      .tout-banner-header {
        padding: 0px 0px 0px 0px;
        &.flair {
          &:before {
            content: ' ';
            background-image: url(../assets/icon-three-left.svg);
            background-size: 33px 33px;
            background-repeat: no-repeat;
            height: 33px;
            width: 33px;
            display: inline-block;
            position: absolute;
            top: 10%;
            left: 17%;
          }
          &:after {
            content: ' ';
            background-image: url(../assets/icon-three-right.svg);
            background-size: 33px 33px;
            background-repeat: no-repeat;
            height: 33px;
            width: 33px;
            display: inline-block;
            position: absolute;
            top: 10%;
            left: 75%;
          }
        }
        .tout-banner-header__accent {
          min-height: 1.25rem;
          margin-bottom: -1.2rem;
          
          &:empty {
            display: none;
          }
        }
        .tout-banner-header__main {
          //margin-top: 1rem;
          line-height: 2rem/* 24/16 */;          
          width: 90%;
          margin: 0 auto;
          //margin-bottom: .5rem;
        }
      }
      .tout-banner__body {
        display:block;
        width: 90%;
        margin: 0 auto;
        //margin-bottom: 1rem;
        //margin-top: .5rem;
      }

      .tout-banner__button {
        a {
          display: inline-block;
          //margin-top: .5rem;
        }
      }
    }
    
  }
  &.secondary {
    padding: 2rem 2rem 2rem 3rem;
    margin-bottom: 2.313rem/* 37/16 */;
    color: #ffffff;
    text-align: center;
    background-image: url(../assets/bg-secondary-tout.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    .h1 {
      margin: 0 auto;
    }
    p {
      display: block;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 3.375rem;
      /* 54/16 */
    }
    input {
      margin: 0 auto;
    }
  }
}

@include media(">=tablet") {
  .tout {
    .tout-wrapper {
    margin-bottom:0rem/* 37/16 */;
      ;
      img {
        height: 500px;
        width: 100%;
      }
      .tout-banner {
        animation: tout-banner-out 150ms ease-in-out forwards;
        position: absolute;
        margin: 0 auto;
        left: 10%;     
        margin-bottom:0rem/* 37/16 */;  
        .tout-banner-header { 
          .tout-banner-header__accent {          
            margin-top: -1rem;
            margin-bottom: -1.7rem;
          }   
        }
        .tout-banner__body {
          animation: tout-hide-body 150ms ease-in-out forwards;  
        } 
        .tout-banner__button {
          animation: tout-hide-button-space 150ms ease-in-out forwards;
          a {            
            animation: tout-button-hide 150ms ease-in-out forwards;
          }
        }
      }     
    }
    &.secondary {           
      height: 500px;
      width: 100%;
      margin: 0 auto;  

    }

    &:hover {
      .tout-banner {
        animation: tout-banner-in 150ms ease-in-out forwards;
        
        .tout-banner__body {
          animation: tout-show-body 150ms ease-in-out forwards;
        } 
        .tout-banner__button {
          animation: tout-show-button-space 150ms ease-in-out forwards;
          a {                   
            animation: tout-button-reveal 250ms ease-in-out forwards;
          }
        }
      }
    }
  }
}

@keyframes tout-banner-in {
  from {
    margin-top: -7rem;
    padding-bottom: 1rem;
  }
  to {
    margin-top: -12rem;
    padding-bottom: 1rem;
  }
}

@keyframes tout-banner-out {  from {
  margin-top: -12rem; 
  padding-bottom: 1rem;
}
to {
  margin-top: -7rem;
  padding-bottom: 1rem;
}
}

@keyframes tout-hide-body {
  from {
    margin-top: .5rem;
    margin-bottom: .5rem;
    opacity: 1;
    height: 24px;
  }
  to {
    margin-top: 0rem;
    margin-bottom: 0rem;
    opacity: 0;
    height: 0px;
  }
}

@keyframes tout-show-body {
  from {
    margin-top: 0rem;
    margin-bottom: 0rem;
    opacity: 0;
    height: 0;
  }
  to {
    margin-top: .5rem;
    margin-bottom: .5rem;
    opacity: 1;
    height: 24px;
  }
}

@keyframes tout-hide-button-space {
  from {
    opacity: 1;
    height: 47px;
    padding-bottom: 1rem;
  }
  to {
    opacity: 0;
    height: 0px;
    padding-bottom: 0rem;
  }  
}

@keyframes tout-show-button-space {
  from {
    opacity: 0;
    height: 0px;
    
  }
  to {
    opacity: 1;
    height: 47px;
    
  }  
}
 
@keyframes tout-button-reveal {
  from {
    opacity: 0;
    margin-top: 1rem
  }
  to {
    opacity: 1;
    margin-top: 0rem;
  }

}
@keyframes tout-button-hide {
  from {
    opacity: 1;
    margin-top: 0rem;
  }
  to {
    opacity: 0;
    margin-top: 1rem;
  }  
}