.menu-item {
  margin-bottom: 2rem;

  img {
    width: 100%;
  }

  .menu-item__name {
    width: 90%;
    margin: 0 auto;
    text-align: center;    
  }

  .menu-item-details {
    input[type="button"] {
      display: block;
      margin: 0 auto;
    }
  }

  .menu-item-details__card {
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem /* 25/16 */;
    overflow: hidden;
    
    
    &[data-visible="false"] {
      animation: details-card-hide 250ms ease-in-out forwards;
    }
    &[data-visible="true"] {
      animation: details-card-reveal 250ms ease-in-out forwards;
    }

    .card-section {
      margin-top: 2rem;
      clear:both;
      .card-section__header {
        span {
          display: inline-block;
        }

        i {
          float: right;
        }
      }

      .card-section__body {

      }

      &.description {}
      &.nutrition {
        .nutrition-facts-list {
          background-color: $grey;
          display: flex;
          justify-content: space-evenly;


        }
        .nutrition-fact {
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: 10px;

          span {
            &:first-child {
              color: $orange;
              font-weight: bold;
            }
          }


        }

        .nutrition-details  {
          margin-top: 1rem;
          width: 50%;
          font-size: 0.625rem; /* 10/16 */

          &.left { float:left;}
          &.right {
            float: right;
            &:after {
              clear:both;
            }
          }

          &.left, &.right {
            span {
              display: inline-block;

              &:first-child {
                color: $light-brown;
                text-align: left;
                width: 60%;
              }

              &:last-child {
                width: 30%;
              }
            }
          }
        }
      }

      &.allergens {
        .card-section__body {
          color: $light-brown;
        }
      }

    }
  }
}

@keyframes details-card-reveal {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 359px;
    opacity: 1;
  }
}

@keyframes details-card-hide {
  from {
    height: 359px;
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}