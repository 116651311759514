.page-blog-category {
    
    .blog-latest {
        margin-top: -5rem;
        h1 {
            display: none;
        }
    }

    .blog-post-list {
        h1 {
            display: none;
        }
    }
}