.location-card, .location-banner-info-card {
  border: solid 3px;
  border-color: $orange;
  width: 90%;
  margin: 0 auto;
  margin-top: 1.875rem /* 30/16 */;
  padding: 1.25rem /* 20/16 */;
  background-color: #ffffff;

  .location-card__restaurant-overview {
    display:flex;
    flex-direction: row;

    .restaurant-overview__image {
      flex-basis: 30%;
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
      }

      div {
        color: $orange;
      }

    }

    .restaurant-overview__address {
      flex-basis: 70%;

      .restaurant-overview__store-name {
          font-size: 1.25rem /* 20/16 */;
          color: $dark-brown;
          text-transform: uppercase;
      }

      .restaurant-overview__store-contact {
        color: $light-brown;
        margin-bottom: .5rem;
        line-height: 1.2rem;
      }

      .restaurant-overview__amenities {
        margin-bottom: 1rem;
        background-color: #ffffff;
        //padding: 1.063rem 2.375rem 1.063rem 2.375rem;
        display: flex;
        justify-content: center;

        img {
          width: 1rem /* 30/16 */;

          &[data-available="false"] {
            opacity: 0.1;
          }
        }

          .ammenity {
            margin-left: 10px;
          span {
            display: none;
            position: absolute;
            z-index: 1;
            line-height: 20px;
            padding: 5px;
            font-size: 12px;
            text-align: center;
            color: $dark-brown;
            background: rgb(255, 255, 255);
            border: 4px solid rgb(255, 255, 255);
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px 0px;
          }
          span:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent #FFFFFF transparent;
            top: -24px;
            left: 0px;
          }

          
        }
        
      }
    }
  }
  .location-card__buttons {

    display: flex;
    justify-content: space-evenly;

    a {
      font-size: 0.875rem;
    }

  }
}

.location-banner-info-card {
  border: none;
  width: 100%;

  .restaurant-overview__image {
    flex-basis: 30%;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      width: 6rem;

    }

    div {
      display: none;
    }
  }

  .location-banner-info-card__change {
    text-align: center;
  }
}
