.blog-category-horizontal {
    text-align: center;
    background-color: $orange;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    .menu-item-small {
        a {
            img {
                min-width: 10.5rem;
                max-width: 10.5rem;
                margin-bottom: 1rem;
            }
        }
    }
}