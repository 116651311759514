$font-path: '../assets/fonts/' !default;

@font-face {
  font-family: 'Century Gothic';
  src: url('#{$font-path}GOTHIC.TTF')  format('truetype')
}

@font-face {
  font-family: 'Century Gothic Bold';
  src: url('#{$font-path}GOTHICB.TTF')  format('truetype')
}

@font-face {
  font-family: 'Century Gothic Bold Italic';
  src: url('#{$font-path}GOTHICBI.TTF')  format('truetype')
}

@font-face {
  font-family: 'Century Gothic Italic';
  src: url('#{$font-path}GOTHICI.TTF')  format('truetype')
}

@font-face {
  font-family: 'Century Gothic Painted';
  src: url('#{$font-path}CenturyGothic-SimplePainted-Regular.otf')  format('opentype')
}

@font-face {
  font-family: 'Signalist';
  src: url('#{$font-path}Signalist.otf') format('opentype')
}

body {
  font-size: 16px /* 16/16 */;
  font-family: "Century Gothic Bold", sans-serif !important;
}

p {
  font-family: "Century Gothic Bold";
  font-size: 1rem;
}

h1, .h1 {
  font-family: "Century Gothic Painted";
  font-size: 1.5rem;
}

h2, .h2 {
  font-family: "Century Gothic Painted";
  font-size: 1.375rem;
}

h3, .h3 {
  font-family: "Century Gothic Painted";
  font-size: 1.125rem;
}

h4, .h4 {
  font-family: "Century Gothic Painted";
  font-size: 1rem;
}

h5, .h5 {
  font-family: "Century Gothic Bold";
  font-size: 0.875rem;
}

h6, .h6 {
  font-family: "Century Gothic Bold";
  font-size: 0.625rem;
}

.accent-big {
  font-family: "Signalist";
  font-size: 1.875rem;

  &.orange {
    color: $orange;
  }

  &.light-brown  {
    color: $light-brown;
  }

  &.dark-brown {
    color: $dark-brown;
  }

  &.light-blue {
    color: $light-blue;
  }

  &.dark-blue {
    color: $dark-blue;
  }
}

.accent-small {
  font-family: "Signalist";
  font-size: 1.25rem;

  &.orange {
    color: $orange;
  }

  &.light-brown  {
    color: $light-brown;
  }

  &.dark-brown {
    color: $dark-brown;
  }

  &.light-blue {
    color: $light-blue;
  }

  &.dark-blue {
    color: $dark-blue;
  }
}

@include media(">=tablet"){
  body {
    font-size: 20px !important;
  }

  h1, .h1 {   
    font-size: 1.875rem;
  }

  h4, .h4 {
    font-size: 1.5rem /* 24/16 */;
  }

  .accent-small {
    font-size: 1.5rem;
  }

  .accent-big {
   
    font-size: 3rem /* 48/16 */;
  }
}
