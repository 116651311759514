.tout-dav {
  background-color: $dark-brown;
  display: flex;
  justify-content: center;
  padding: 1.438rem /* 23/16 */;
  align-items: center;
  .tout-dav__header {
    color: #ffffff;
    font-size: 1.25rem /* 20/16 */;
    text-align: center;
    text-transform: uppercase;
    margin-right: 1rem;
  }
  .tout-dav__logo {
    img {
      width: 6.938rem /* 111/16 */;
    }
  }
}
