input[type="button"], a {
    border: none;

    &.aw-btn-primary {
      color: $white;
      font-family: "Century Gothic Bold";
      font-size: 0.875rem;
      text-transform: uppercase;
      padding: 0.625rem /* 10/16 */ 1.5rem /* 16/16 */ 0.625rem /* 10/16 */ 1.5rem /* 16/16 */;
      border-radius: 2rem;
    }

    &.aw-btn-secondary {
      color: $orange;
      border-bottom: 1px;
      border-bottom-style: solid;
      padding: 0px 0.625rem /* 10/16 */ 0px 0.625rem /* 10/16 */;
      //background-color: #ffffff;
      background-color: transparent;
    }

    &.orange {
      background-color: $orange;
    }

    &.dark-brown {
      background-color: $dark-brown;
    }

    &.light-blue {
      background-color: $light-blue;
    }
}

button:focus {outline:0;}


@include media(">=tablet") {
  input[type="button"], a {
    

    &.aw-btn-primary {
    
      font-size: 1.125rem /* 18/16 */;
    
    }

    
}
}
