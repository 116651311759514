.aw-field {
  position: relative;
  max-width: 21.563rem /* 345/16 */;
  font-family: "Century Gothic Bold";
/*******************************************************************************
 ********************************* CHECK BOXES *********************************
 *******************************************************************************/
  &.checkbox {
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;

      &:checked + label {
        &:before {
          background: #000000;
          box-shadow: inset 0 0 0 0.125rem /* 2/16 */ #fff;
        }
      }

      &:focus + label {
        outline: 1px solid #ddd;
        /* focus style */
      }
    }

    label {
      position: relative;
    }

    input[type="checkbox"],
    label {
      display: inline-block;
      vertical-align: middle;
      margin: 0.313rem /* 5/16 */;
      cursor: pointer;
    }

    input[type="checkbox"] + label {
      &:before {
        content: '';
        background: #fff;
        border: 0.188rem /* 3/16 */ solid #000;
        display: block;
        float: left;
        vertical-align: middle;
        width: 1.25rem /* 20/16 */;
        height: 1.25rem /* 20/16 */;
        padding: 0.125rem /* 2/16 */;
        margin-right: 0.625rem /* 10/16 */;
        text-align: center;
        border-radius: 0.438rem /* 7/16 */;
      }
    }

    &.legal {
      font-size: 0.625rem /* 10/16 */;
    }

    &.filter {
      font-size: 0.875rem /* 14/16 */;
    }
  }
/*******************************************************************************
 ************************************ SELECT   *********************************
 *******************************************************************************/
  &.select {
    border: 0.125rem /* 2/16 */ solid;
    border-radius: 2.063rem /* 33/16 */;
    overflow: hidden;
    font-size: 0.875rem;

    select {
      padding: 0.625rem 1.562rem;
      transition: all 0.2s ease-out;
      width: 100%;
      border-radius: 2rem;
      border-style: solid;
      border: none;
      box-shadow: none;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      background-color: #ffffff;

      &:focus {
        outline: none;
      }
    }

    i {
      position: absolute;
      top: 0.9rem;
      right: .5rem;
    }
  }
/*******************************************************************************
 ************************************ RADIO BUTTONS  ***************************
 *******************************************************************************/
  &.switch {
    margin-bottom: 4rem;

    .switch-title {
      margin-bottom: 0.375rem /* 6/16 */;
    }

    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;

      &:checked + label {
        background-color: $orange;
        color: #ffffff;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    label {
      display: inline-block;
      float: left;
      background-color: #ffffff;
      color: $light-brown;
      font-size: 1rem;
      font-weight: normal;
      text-align: center;
      text-shadow: none;
      padding: 0.375rem /* 6/16 */ 1.125rem /* 18/16 */;
      -webkit-box-shadow: inset 0 1px 0.188rem /* 3/16 */ rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
      box-shadow: inset 0 1px 0.188rem /* 3/16 */ rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
      -webkit-transition: all 0.1s ease-in-out;
      -moz-transition: all 0.1s ease-in-out;
      -ms-transition: all 0.1s ease-in-out;
      -o-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;

      &:hover {
        cursor: pointer;
      }

      &:first-of-type {
        border-radius: 1.875rem /* 30/16 */ 0 0 1.875rem /* 30/16 */;
        border-left: 0.125rem /* 2/16 */ solid $light-brown;
        border-top: 0.125rem /* 2/16 */ solid $light-brown;
        border-bottom: 0.125rem /* 2/16 */ solid $light-brown;
      }

      &:last-of-type {
        border-radius: 0 1.875rem /* 30/16 */ 1.875rem /* 30/16 */ 0;
        border-right: 0.125rem /* 2/16 */ solid $light-brown;
        border-top: 0.125rem /* 2/16 */ solid $light-brown;
        border-bottom: 0.125rem /* 2/16 */ solid $light-brown;
        border-left: 0.125rem /* 2/16 */ solid $light-brown;
      }
    }
  }
/*******************************************************************************
 ************************************ TEXT INPUTS ******************************
 *******************************************************************************/
  .aw-field__label {
    box-sizing: border-box;
    display: block;
    font-size: 0.6em;
    line-height: 1;
    opacity: 0;
    overflow: hidden;
    padding: 0.625rem 1.625rem;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    text-align: left;
    transform: translateY(0.188rem /* 3/16 */);
    transition: all 0.2s ease-out;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
  }

  .aw-field__input {
    border: 0.125rem /* 2/16 */ solid;
    font-size: 0.875rem;
    padding: 0.625rem 1.562rem;
    transition: all 0.2s ease-out;
    width: 100%;
    border-radius: 2rem;
    border-style: solid;

    &:focus,
    &:hover {
      box-shadow: inset 0 0 0.625rem /* 10/16 */ 0 rgba(85, 85, 85, .1);
      outline: 0;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000000;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #000000;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #000000;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #000000;
  }
/*******************************************************************************
 ************************************ SEARCH INPUTS ****************************
 *******************************************************************************/
  &.search {
    i {
      position: absolute;
      top: .5rem;
      left: 88%;
    }

    i.fas {
      display: inline-block;
      border-radius: 4rem /* 64/16 */;
      box-shadow: 0 0 0.125rem /* 2/16 */ #888;
      padding: 0.4rem 0.4rem;
      background-color: #000;
    }
  }
}

.aw-field--not-empty {
  .aw-field__label {
    opacity: 1;
    transform: none;
  }
}

.aw-field--not-empty {
  .aw-field__input {
    padding-bottom: 0.125rem /* 2/16 */;
    padding-top: 1.125rem /* 18/16 */;
  }
}
