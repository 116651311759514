.rooty-speech-bubble {
  width: 80%;
  display:flex;
  margin: 0 auto;
  margin-bottom: 2rem;

  .rooty-speech-bubble__rooty {
    position: relative;
    min-width: 4.375rem;
    img {
      display: block;
      width: 4.375rem;
      position: absolute;
      bottom: -1rem;
      left: 0;
    }
  }
  .rooty-speech-bubble__speech-bubble{
    p {
      margin-bottom: 0px;
      text-align: left;
      margin-left: 1rem;
    }
  }
}

.rectangle-speech-border {
  position:relative;
  padding:10px 10px;
  margin:0 0 1em;
  border:3px solid $light-brown;
  text-align:center;
  color:$light-brown;
  background:#fff;
  /* css3 */
  -webkit-border-radius:40px;
  -moz-border-radius:40px;
  border-radius:40px;
}

/* creates larger curve */
.rectangle-speech-border:before {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -20px;
  left: 7px;
  width: 50px;
  height: 18px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: $light-brown;
  background: transparent;
  border-bottom-right-radius: 104px 50px;
  display: block;
  /* css3 */
  -webkit-border-bottom-right-radius:104px 50px;
  -moz-border-radius-bottomright:104px 50px;
  border-bottom-right-radius:104px 50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates smaller curve */
.rectangle-speech-border:after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -20px;
  left: 10px;
  width: 20px;
  height: 18px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: $light-brown;
  background: transparent;
  border-bottom-right-radius: 23px 30px;
  display: block;
  /* css3 */
  -webkit-border-bottom-right-radius:23px 30px;
  -moz-border-radius-bottomright:23px 30px;
  border-bottom-right-radius:23px 30px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates a small circle to produce a rounded point where the two curves meet */
.rectangle-speech-border > :first-child:before {
  /*content:"";
  position:absolute;
  bottom:-40px;
  left:50px;
  width:3px;
  height:3px;
  background:#5a8f00;*/
  /* css3 */
  /*-webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;*/
}

/* creates a white rectangle to cover part of the oval border*/
.rectangle-speech-border > :first-child:after {
  content:"";
  position:absolute;
  bottom:-10px;
  left:30px;
  width:24px;
  height:15px;
  background:#fff;
}
