
.horizontal-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  

  .horizontal-scroller__item {
    display: inline-block;
  }
}
