img.aw-image {
  display: block;
  height: auto;

  &.logo {
    //width: 4.25rem /* 68/16 */;
  }

  &.location {
    //width: 10.938rem /* 175/16 */;
  }

  &.location-round {
    //width: 4.688rem /* 75/16 */;
    border-radius: 100%;
  }

  &.circle-menu-item {
    //width: 6.25rem /* 100/16 */;
    border-radius: 100%;
  }

  &.lto {
    //width: 5.938rem /* 95/16 */;
  }

  &.blog-img {
    //width: 9.375rem /* 150/16 */;
    border-radius: 100%;
  }

  &.tout {
    //width: 21.563rem /* 345/16 */;

    & .secondary {
      //width: 23.438rem /* 375/16 */;
    }
  }

  &.header {
    //width: 23.438rem /* 375/16 */;
  }

  &.map {
    //width: 21.563rem /* 345/16 */;
  }

  &.food {
    //width: 21.563rem /* 345/16 */;
  }

  &.social {
    //width: 14.063rem /* 225/16 */;
  }

  &.facebook-embed {
    //width: 21.563rem /* 345/16 */;
  }
}

div.img-swatch-mobile {
  width: 23.438rem /* 375/16 */;
  img {
    display: block;
    margin-bottom: 2rem;
  }
}

div.img-swatch-tablet {
  width: 48rem /* 768/16 */;
}

div.img-swatch-desktop {
  width: 75rem /* 1200/16 */;
}

.aw-bg {
  background-image: url(../assets/bg.svg);
  background-size: 500px;
  background-repeat: repeat;
}

.aw-bg-dark {
  background-image: url(../assets/bg-dark.svg);
  background-size: 500px;
  background-repeat: repeat;
}
