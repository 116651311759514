.store-header {
  margin-bottom: 1.5rem/* 24/16 */
  ;
  margin-top: -3rem;
  .store-header__main {
    color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 3rem;
    text-align: center;
    background-image: url(../assets/bg-secondary-tout.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .store-header__main-wrapper {
    .store-header__details {
      width: 90%;
      margin: 0 auto;
      margin-top: -2rem;
      .store-header__details-ammenities {
        background-color: #ffffff;
        padding: 1.063rem 2.375rem 1.063rem 2.375rem;
        display: flex;
        justify-content: space-around;
        img {
          display: block;
          height: 1.875rem/* 30/16 */
          ;
          &[data-available="false"] {
            opacity: 0.1;
          }
        }
        .ammenity {
          span {
            display: none;
            position: absolute;
            z-index: 1;
            line-height: 10px;
            padding: 5px;
            font-size: 12px;
            text-align: center;
            color: $dark-brown;
            background: rgb(255, 255, 255);
            border: 4px solid rgb(255, 255, 255);
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px 0px;
          }
          span:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent #FFFFFF transparent;
            top: -24px;
            left: 0px;
          }
        }
        .ammenity {
          &:hover {
            span {
              display: block;
            }
          }
        }
      }
      .store-header__details-hours {
        margin-bottom: 1.5rem/* 24/16 */
        ;
        .hours__row {
          display: flex;
          flex-direction: row;
          width: 75%;
          margin: 0 auto;
          justify-content: space-between;
          & :first-child {
            color: $dark-brown;
          }
          & :last-child {
            color: $light-brown;
          }
        }
        >.hours__row {
          border-top: 1px solid;
          border-color: grey;
          &:first-child {
            border-top: none;
          }
        }
      }
      .store-header__details-buttons {
        text-align: center;
        display: flex;
        justify-content: space-evenly;
      }
    }
    .store-header__current-deal {
      margin-top: 1rem;
    }
    .store-header__rooty-mobile {
      display: block;
    }
  }
  .store-header__map-wrapper {
    display: none;
    iframe {
      display: none;
    }
  }
}

@include media(">=tablet") {
  .store-header {
    .store-header__main-wrapper {
      max-width: 50%;
      float: left;
      .store-header__rooty-mobile {
        display: none;
      }
      .store-header__social {
        .store-social__header {
          display: none !important;
        }
      }
    }
    .store-header__map-wrapper {
      display: block;
      margin: 0 auto;
      max-width: 50%;
      float: right;
      margin-top: -2rem;
      z-index: 2;
      iframe {
        width: 100%;
        height: 415px;
        margin: 0;
        display: block;
      }
      .store-header__map-wrapper-rooty {
        margin-top: -2rem;
        margin-left: 9rem;
      }
    }
  }
}