*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

main {
  min-width: 375px;
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

section.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

section.flex-row .flex-10, section.flex-row .flex-20, section.flex-row .flex-30, section.flex-row .flex-40, section.flex-row .flex-50, section.flex-row .flex-60, section.flex-row .flex-70, section.flex-row .flex-80, section.flex-row .flex-90, section.flex-row .flex-100 {
  flex-basis: 100%;
}

section.flex-row.no-margin {
  margin-bottom: 0rem;
}

@media (min-width: 768px) {
  section.flex-row .flex-10 {
    flex-basis: 8%;
  }
  section.flex-row .flex-20 {
    flex-basis: 18%;
  }
  section.flex-row .flex-30 {
    flex-basis: 28%;
  }
  section.flex-row .flex-40 {
    flex-basis: 38%;
  }
  section.flex-row .flex-50 {
    flex-basis: 48%;
  }
  section.flex-row .flex-60 {
    flex-basis: 58%;
  }
  section.flex-row .flex-70 {
    flex-basis: 68%;
  }
  section.flex-row .flex-80 {
    flex-basis: 78%;
  }
  section.flex-row .flex-90 {
    flex-basis: 88%;
  }
  section.flex-row .flex-100 {
    flex-basis: 98%;
  }
}

body {
  background-color: whitesmoke;
  margin-top: -5px;
}

main {
  background-color: #ffffff;
}

/*Additional Styles for Debugging*/
div.swatch {
  display: inline-block;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 1.563rem;
  border-color: black;
  border-width: 0.188rem;
  border-style: solid;
}

div.swatch.white {
  background-color: #ffffff;
}

div.swatch.light-brown {
  background-color: #963821;
}

div.swatch.dark-brown {
  background-color: #4f2c1d;
}

div.swatch.orange {
  background-color: #f26c31;
}

div.swatch.light-blue {
  background-color: #56c9e8;
}

div.swatch.dark-blue {
  background-color: #006e9f;
}

@font-face {
  font-family: 'Century Gothic';
  src: url("../assets/fonts/GOTHIC.TTF") format("truetype");
}

@font-face {
  font-family: 'Century Gothic Bold';
  src: url("../assets/fonts/GOTHICB.TTF") format("truetype");
}

@font-face {
  font-family: 'Century Gothic Bold Italic';
  src: url("../assets/fonts/GOTHICBI.TTF") format("truetype");
}

@font-face {
  font-family: 'Century Gothic Italic';
  src: url("../assets/fonts/GOTHICI.TTF") format("truetype");
}

@font-face {
  font-family: 'Century Gothic Painted';
  src: url("../assets/fonts/CenturyGothic-SimplePainted-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Signalist';
  src: url("../assets/fonts/Signalist.otf") format("opentype");
}

body {
  font-size: 16px;
  font-family: "Century Gothic Bold", sans-serif !important;
}

p {
  font-family: "Century Gothic Bold";
  font-size: 1rem;
}

h1, .h1 {
  font-family: "Century Gothic Painted";
  font-size: 1.5rem;
}

h2, .h2 {
  font-family: "Century Gothic Painted";
  font-size: 1.375rem;
}

h3, .h3 {
  font-family: "Century Gothic Painted";
  font-size: 1.125rem;
}

h4, .h4 {
  font-family: "Century Gothic Painted";
  font-size: 1rem;
}

h5, .h5 {
  font-family: "Century Gothic Bold";
  font-size: 0.875rem;
}

h6, .h6 {
  font-family: "Century Gothic Bold";
  font-size: 0.625rem;
}

.accent-big {
  font-family: "Signalist";
  font-size: 1.875rem;
}

.accent-big.orange {
  color: #f26c31;
}

.accent-big.light-brown {
  color: #963821;
}

.accent-big.dark-brown {
  color: #4f2c1d;
}

.accent-big.light-blue {
  color: #56c9e8;
}

.accent-big.dark-blue {
  color: #006e9f;
}

.accent-small {
  font-family: "Signalist";
  font-size: 1.25rem;
}

.accent-small.orange {
  color: #f26c31;
}

.accent-small.light-brown {
  color: #963821;
}

.accent-small.dark-brown {
  color: #4f2c1d;
}

.accent-small.light-blue {
  color: #56c9e8;
}

.accent-small.dark-blue {
  color: #006e9f;
}

@media (min-width: 768px) {
  body {
    font-size: 20px !important;
  }
  h1, .h1 {
    font-size: 1.875rem;
  }
  h4, .h4 {
    font-size: 1.5rem;
  }
  .accent-small {
    font-size: 1.5rem;
  }
  .accent-big {
    font-size: 3rem;
  }
}

.aw-icon {
  text-decoration: inherit;
  font-size: 1rem;
}

.aw-icon.fa-search {
  color: #fff;
  display: inline-block;
  border-radius: 4rem;
  box-shadow: 0 0 0.125rem #888;
  padding: 0.4rem 0.4rem;
  background-color: #000;
}

.aw-icon.fa-angle-down {
  text-decoration: inherit;
  color: #000;
  font-size: 1rem;
}

.aw-icon.fa-crosshairs {
  color: #fff;
  display: inline-block;
  border-radius: 4rem;
  box-shadow: 0 0 0.125rem #888;
  padding: 0.4rem 0.4rem;
  background-color: #000;
}

.aw-field {
  position: relative;
  max-width: 21.563rem;
  font-family: "Century Gothic Bold";
  /*******************************************************************************
 ********************************* CHECK BOXES *********************************
 *******************************************************************************/
  /*******************************************************************************
 ************************************ SELECT   *********************************
 *******************************************************************************/
  /*******************************************************************************
 ************************************ RADIO BUTTONS  ***************************
 *******************************************************************************/
  /*******************************************************************************
 ************************************ TEXT INPUTS ******************************
 *******************************************************************************/
  /*******************************************************************************
 ************************************ SEARCH INPUTS ****************************
 *******************************************************************************/
}

.aw-field.checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.aw-field.checkbox input[type="checkbox"]:checked + label:before {
  background: #000000;
  box-shadow: inset 0 0 0 0.125rem #fff;
}

.aw-field.checkbox input[type="checkbox"]:focus + label {
  outline: 1px solid #ddd;
  /* focus style */
}

.aw-field.checkbox label {
  position: relative;
}

.aw-field.checkbox input[type="checkbox"],
.aw-field.checkbox label {
  display: inline-block;
  vertical-align: middle;
  margin: 0.313rem;
  cursor: pointer;
}

.aw-field.checkbox input[type="checkbox"] + label:before {
  content: '';
  background: #fff;
  border: 0.188rem solid #000;
  display: block;
  float: left;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem;
  margin-right: 0.625rem;
  text-align: center;
  border-radius: 0.438rem;
}

.aw-field.checkbox.legal {
  font-size: 0.625rem;
}

.aw-field.checkbox.filter {
  font-size: 0.875rem;
}

.aw-field.select {
  border: 0.125rem solid;
  border-radius: 2.063rem;
  overflow: hidden;
  font-size: 0.875rem;
}

.aw-field.select select {
  padding: 0.625rem 1.562rem;
  transition: all 0.2s ease-out;
  width: 100%;
  border-radius: 2rem;
  border-style: solid;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  background-color: #ffffff;
}

.aw-field.select select:focus {
  outline: none;
}

.aw-field.select i {
  position: absolute;
  top: 0.9rem;
  right: .5rem;
}

.aw-field.switch {
  margin-bottom: 4rem;
}

.aw-field.switch .switch-title {
  margin-bottom: 0.375rem;
}

.aw-field.switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.aw-field.switch input:checked + label {
  background-color: #f26c31;
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.aw-field.switch label {
  display: inline-block;
  float: left;
  background-color: #ffffff;
  color: #963821;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 0.375rem 1.125rem;
  -webkit-box-shadow: inset 0 1px 0.188rem rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0.188rem rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.aw-field.switch label:hover {
  cursor: pointer;
}

.aw-field.switch label:first-of-type {
  border-radius: 1.875rem 0 0 1.875rem;
  border-left: 0.125rem solid #963821;
  border-top: 0.125rem solid #963821;
  border-bottom: 0.125rem solid #963821;
}

.aw-field.switch label:last-of-type {
  border-radius: 0 1.875rem 1.875rem 0;
  border-right: 0.125rem solid #963821;
  border-top: 0.125rem solid #963821;
  border-bottom: 0.125rem solid #963821;
  border-left: 0.125rem solid #963821;
}

.aw-field .aw-field__label {
  box-sizing: border-box;
  display: block;
  font-size: 0.6em;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: 0.625rem 1.625rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  text-align: left;
  transform: translateY(0.188rem);
  transition: all 0.2s ease-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.aw-field .aw-field__input {
  border: 0.125rem solid;
  font-size: 0.875rem;
  padding: 0.625rem 1.562rem;
  transition: all 0.2s ease-out;
  width: 100%;
  border-radius: 2rem;
  border-style: solid;
}

.aw-field .aw-field__input:focus, .aw-field .aw-field__input:hover {
  box-shadow: inset 0 0 0.625rem 0 rgba(85, 85, 85, 0.1);
  outline: 0;
}

.aw-field ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000;
}

.aw-field ::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
}

.aw-field :-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}

.aw-field :-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
}

.aw-field.search i {
  position: absolute;
  top: .5rem;
  left: 88%;
}

.aw-field.search i.fas {
  display: inline-block;
  border-radius: 4rem;
  box-shadow: 0 0 0.125rem #888;
  padding: 0.4rem 0.4rem;
  background-color: #000;
}

.aw-field--not-empty .aw-field__label {
  opacity: 1;
  transform: none;
}

.aw-field--not-empty .aw-field__input {
  padding-bottom: 0.125rem;
  padding-top: 1.125rem;
}

input[type="button"], a {
  border: none;
}

input[type="button"].aw-btn-primary, a.aw-btn-primary {
  color: #ffffff;
  font-family: "Century Gothic Bold";
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 0.625rem 1.5rem 0.625rem 1.5rem;
  border-radius: 2rem;
}

input[type="button"].aw-btn-secondary, a.aw-btn-secondary {
  color: #f26c31;
  border-bottom: 1px;
  border-bottom-style: solid;
  padding: 0px 0.625rem 0px 0.625rem;
  background-color: transparent;
}

input[type="button"].orange, a.orange {
  background-color: #f26c31;
}

input[type="button"].dark-brown, a.dark-brown {
  background-color: #4f2c1d;
}

input[type="button"].light-blue, a.light-blue {
  background-color: #56c9e8;
}

button:focus {
  outline: 0;
}

@media (min-width: 768px) {
  input[type="button"].aw-btn-primary, a.aw-btn-primary {
    font-size: 1.125rem;
  }
}

img.aw-image {
  display: block;
  height: auto;
}

img.aw-image.location-round {
  border-radius: 100%;
}

img.aw-image.circle-menu-item {
  border-radius: 100%;
}

img.aw-image.blog-img {
  border-radius: 100%;
}

div.img-swatch-mobile {
  width: 23.438rem;
}

div.img-swatch-mobile img {
  display: block;
  margin-bottom: 2rem;
}

div.img-swatch-tablet {
  width: 48rem;
}

div.img-swatch-desktop {
  width: 75rem;
}

.aw-bg {
  background-image: url(../assets/bg.svg);
  background-size: 500px;
  background-repeat: repeat;
}

.aw-bg-dark {
  background-image: url(../assets/bg-dark.svg);
  background-size: 500px;
  background-repeat: repeat;
}

.horizontal-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.horizontal-scroller .horizontal-scroller__item {
  display: inline-block;
}

.aw-nav {
  display: flex;
  justify-content: space-between;
  font-family: "Century Gothic Painted";
  margin: 0.313rem;
  padding-top: 0.5rem;
}

.aw-nav .aw-nav__logo .logo-desktop {
  display: none;
}

.aw-nav .aw-nav__logo .logo-mobile {
  display: block;
}

.aw-nav .aw-nav__links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aw-nav .aw-nav__links a {
  margin-left: 1.625rem;
  color: #963821;
}

.aw-nav .aw-nav__links a.aw-btn-primary {
  color: #ffffff;
}

.aw-nav .aw-nav__links a.aw-btn-primary[data-online-ordering="false"] {
  visibility: hidden;
  width: 0px;
  margin-right: -50px;
}

.aw-nav .aw-nav__links a.aw-btn-primary[data-online-ordering="true"] {
  visibility: visible;
  width: unset;
  margin-right: 0;
}

.aw-nav img {
  display: block;
  width: 4.25rem;
  z-index: 5;
}

@media (min-width: 768px) {
  .aw-nav {
    display: flex;
    justify-content: space-between;
    font-family: "Century Gothic Painted";
    margin: 0.313rem;
  }
  .aw-nav .aw-nav__logo {
    z-index: 4;
  }
  .aw-nav .aw-nav__logo .logo-desktop {
    display: block;
  }
  .aw-nav .aw-nav__logo .logo-mobile {
    display: none;
  }
  .aw-nav .aw-nav__links {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aw-nav .aw-nav__links a {
    margin-top: -40px;
    margin-left: 1.625rem;
    color: #963821;
  }
  .aw-nav .aw-nav__links a.aw-btn-primary {
    color: #ffffff;
  }
  .aw-nav img {
    width: 9.938rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}

.tout .tout-wrapper {
  position: relative;
  margin-bottom: 2.313rem;
}

.tout .tout-wrapper img {
  width: 90%;
  height: 250px;
  margin: 0 auto;
  object-fit: cover;
}

.tout .tout-wrapper .tout-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  border: 0.25rem solid;
  padding-top: 1rem;
  width: 80%;
  text-align: center;
  background-color: #ffffff;
  margin-top: -16%;
  position: relative;
}

.tout .tout-wrapper .tout-banner.orange {
  border-color: #f26c31;
}

.tout .tout-wrapper .tout-banner.light-brown {
  border-color: #963821;
}

.tout .tout-wrapper .tout-banner.dark-brown {
  border-color: #4f2c1d;
}

.tout .tout-wrapper .tout-banner.light-blue {
  border-color: #56c9e8;
}

.tout .tout-wrapper .tout-banner.dark-blue {
  border-color: #006e9f;
}

.tout .tout-wrapper .tout-banner .tout-banner-header {
  padding: 0px 0px 0px 0px;
}

.tout .tout-wrapper .tout-banner .tout-banner-header.flair:before {
  content: ' ';
  background-image: url(../assets/icon-three-left.svg);
  background-size: 33px 33px;
  background-repeat: no-repeat;
  height: 33px;
  width: 33px;
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 17%;
}

.tout .tout-wrapper .tout-banner .tout-banner-header.flair:after {
  content: ' ';
  background-image: url(../assets/icon-three-right.svg);
  background-size: 33px 33px;
  background-repeat: no-repeat;
  height: 33px;
  width: 33px;
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 75%;
}

.tout .tout-wrapper .tout-banner .tout-banner-header .tout-banner-header__accent {
  min-height: 1.25rem;
  margin-bottom: -1.2rem;
}

.tout .tout-wrapper .tout-banner .tout-banner-header .tout-banner-header__accent:empty {
  display: none;
}

.tout .tout-wrapper .tout-banner .tout-banner-header .tout-banner-header__main {
  line-height: 2rem;
  width: 90%;
  margin: 0 auto;
}

.tout .tout-wrapper .tout-banner .tout-banner__body {
  display: block;
  width: 90%;
  margin: 0 auto;
}

.tout .tout-wrapper .tout-banner .tout-banner__button a {
  display: inline-block;
}

.tout.secondary {
  padding: 2rem 2rem 2rem 3rem;
  margin-bottom: 2.313rem;
  color: #ffffff;
  text-align: center;
  background-image: url(../assets/bg-secondary-tout.svg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tout.secondary .h1 {
  margin: 0 auto;
}

.tout.secondary p {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3.375rem;
  /* 54/16 */
}

.tout.secondary input {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .tout .tout-wrapper {
    margin-bottom: 0rem;
  }
  .tout .tout-wrapper img {
    height: 500px;
    width: 100%;
  }
  .tout .tout-wrapper .tout-banner {
    animation: tout-banner-out 150ms ease-in-out forwards;
    position: absolute;
    margin: 0 auto;
    left: 10%;
    margin-bottom: 0rem;
  }
  .tout .tout-wrapper .tout-banner .tout-banner-header .tout-banner-header__accent {
    margin-top: -1rem;
    margin-bottom: -1.7rem;
  }
  .tout .tout-wrapper .tout-banner .tout-banner__body {
    animation: tout-hide-body 150ms ease-in-out forwards;
  }
  .tout .tout-wrapper .tout-banner .tout-banner__button {
    animation: tout-hide-button-space 150ms ease-in-out forwards;
  }
  .tout .tout-wrapper .tout-banner .tout-banner__button a {
    animation: tout-button-hide 150ms ease-in-out forwards;
  }
  .tout.secondary {
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
  .tout:hover .tout-banner {
    animation: tout-banner-in 150ms ease-in-out forwards;
  }
  .tout:hover .tout-banner .tout-banner__body {
    animation: tout-show-body 150ms ease-in-out forwards;
  }
  .tout:hover .tout-banner .tout-banner__button {
    animation: tout-show-button-space 150ms ease-in-out forwards;
  }
  .tout:hover .tout-banner .tout-banner__button a {
    animation: tout-button-reveal 250ms ease-in-out forwards;
  }
}

@keyframes tout-banner-in {
  from {
    margin-top: -7rem;
    padding-bottom: 1rem;
  }
  to {
    margin-top: -12rem;
    padding-bottom: 1rem;
  }
}

@keyframes tout-banner-out {
  from {
    margin-top: -12rem;
    padding-bottom: 1rem;
  }
  to {
    margin-top: -7rem;
    padding-bottom: 1rem;
  }
}

@keyframes tout-hide-body {
  from {
    margin-top: .5rem;
    margin-bottom: .5rem;
    opacity: 1;
    height: 24px;
  }
  to {
    margin-top: 0rem;
    margin-bottom: 0rem;
    opacity: 0;
    height: 0px;
  }
}

@keyframes tout-show-body {
  from {
    margin-top: 0rem;
    margin-bottom: 0rem;
    opacity: 0;
    height: 0;
  }
  to {
    margin-top: .5rem;
    margin-bottom: .5rem;
    opacity: 1;
    height: 24px;
  }
}

@keyframes tout-hide-button-space {
  from {
    opacity: 1;
    height: 47px;
    padding-bottom: 1rem;
  }
  to {
    opacity: 0;
    height: 0px;
    padding-bottom: 0rem;
  }
}

@keyframes tout-show-button-space {
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
    height: 47px;
  }
}

@keyframes tout-button-reveal {
  from {
    opacity: 0;
    margin-top: 1rem;
  }
  to {
    opacity: 1;
    margin-top: 0rem;
  }
}

@keyframes tout-button-hide {
  from {
    opacity: 1;
    margin-top: 0rem;
  }
  to {
    opacity: 0;
    margin-top: 1rem;
  }
}

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  /* added this */
}

.owl-carousel {
  margin-bottom: -2rem;
}

.owl-carousel .owl-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  top: 70px;
  color: white;
  font-size: 3rem;
}

.owl-carousel .owl-nav [class*=owl-]:hover {
  background: none !important;
}

.owl-carousel .owl-dots {
  display: none;
}

@media (min-width: 550px) and (max-width: 767px) {
  .owl-carousel .owl-dots {
    top: 36%;
  }
}

@media (min-width: 768px) {
  .owl-carousel .owl-nav {
    padding-left: 2rem;
    padding-right: 2rem;
    top: 180px;
    font-size: 5rem;
  }
  .owl-carousel .owl-dots {
    display: block;
    position: absolute;
    width: 100%;
    top: 470px;
  }
}

.header .header-wrapper {
  position: relative;
  margin-bottom: 2.313rem;
}

.header .header-wrapper img {
  width: 100%;
  height: 250px;
  margin: 0 auto;
  object-fit: cover;
}

.header .header-wrapper .header-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  border: 0.25rem solid;
  padding: 1rem 0 1rem 0;
  width: 80%;
  text-align: center;
  background-color: #ffffff;
  margin-top: -5rem;
  position: relative;
}

.header .header-wrapper .header-banner.orange {
  border-color: #f26c31;
}

.header .header-wrapper .header-banner.light-brown {
  border-color: #963821;
}

.header .header-wrapper .header-banner.dark-brown {
  border-color: #4f2c1d;
}

.header .header-wrapper .header-banner.light-blue {
  border-color: #56c9e8;
}

.header .header-wrapper .header-banner.dark-blue {
  border-color: #006e9f;
}

.header .header-wrapper .header-banner .header-banner-header {
  padding: 0px 0px 0px 0px;
}

.header .header-wrapper .header-banner .header-banner-header.flair:before {
  content: ' ';
  background-image: url(../assets/icon-three-left.svg);
  background-size: 33px 33px;
  background-repeat: no-repeat;
  height: 33px;
  width: 33px;
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 17%;
}

.header .header-wrapper .header-banner .header-banner-header.flair:after {
  content: ' ';
  background-image: url(../assets/icon-three-right.svg);
  background-size: 33px 33px;
  background-repeat: no-repeat;
  height: 33px;
  width: 33px;
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 75%;
}

.header .header-wrapper .header-banner .header-banner-header .header-banner-header__accent {
  min-height: 1.25rem;
  margin-bottom: -2rem;
}

.header .header-wrapper .header-banner .header-banner-header .header-banner-header__accent:empty {
  display: none;
}

.header .header-wrapper .header-banner .header-banner-header .header-banner-header__main {
  margin-top: 1rem;
  line-height: 2rem;
  margin-bottom: .5rem;
}

.header .header-wrapper .header-banner .header-banner__body {
  display: block;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .header {
    margin-bottom: 1.5%;
  }
  .header .header-wrapper img {
    height: 500px;
  }
  .header .header-wrapper .header-banner {
    margin: 0 auto;
    width: 30%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 5rem;
  }
}

.banner-location-selector {
  text-align: center;
  mask-image: url(../assets/nav-mask.svg);
  mask-size: 375px;
  mask-repeat: repeat-x;
  mask-mode: alpha;
  mask-position: -1px;
  min-height: 2.25rem;
  z-index: 3;
  position: relative;
  margin-bottom: -10px !important;
  color: #ffffff;
}

.banner-location-selector[data-location-selected="false"] {
  background-color: #4f2c1d;
}

.banner-location-selector[data-location-selected="true"] {
  background-image: url(../assets/bg-nav.svg);
  background-size: auto;
}

.banner-location-selector .banner-location-select {
  align-items: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: .5rem;
  padding-right: .5rem;
}

.banner-location-selector .banner-location-select[data-visible="false"] {
  display: none;
}

.banner-location-selector .banner-location-select[data-visible="true"] {
  display: block;
}

.banner-location-selector .banner-location-select .banner-location-select__choose {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 5px;
}

.banner-location-selector .banner-location-select .banner-location-select__choose i {
  margin-left: .5rem;
  color: #ffffff;
}

.banner-location-selector .banner-location-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: .5rem;
  padding-right: .5rem;
}

.banner-location-selector .banner-location-selected[data-visible="false"] {
  visibility: hidden;
  height: 0;
}

.banner-location-selector .banner-location-selected[data-visible="true"] {
  visibility: visible;
  height: 40px;
}

.banner-location-selector .banner-location-selected .banner-location-selected__map-link,
.banner-location-selector .banner-location-selected .banner-location-selected__store-name,
.banner-location-selector .banner-location-selected .banner-location-selected__info-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 5px;
}

.banner-location-selector .banner-location-selected .banner-location-selected__map-link i {
  margin-right: .5rem;
}

.banner-location-selector .banner-location-selected .banner-location-selected__info-link i {
  margin-left: .5rem;
  color: #ffffff;
}

.location-banner-info-card {
  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 1400px;
}

.location-banner-info-card .location-banner-info-card__change hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.banner-location-select__search {
  overflow-y: hidden;
  max-height: 186px;
  /* approximate max height */
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  position: absolute;
  top: 5rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  max-width: 1400px;
  box-shadow: -2px 24px 24px -19px #111;
}

.banner-location-select__search[data-visible="false"] {
  height: 0px;
  padding: 0px;
}

.banner-location-select__search[data-visible="true"] {
  height: 186px;
}

.banner-location-select__search .aw-field {
  margin: 0 auto;
}

.banner-location-select__search span {
  display: block;
}

.banner-location-selected__info-card {
  overflow-y: hidden;
  max-height: 350px;
  /* approximate max height */
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  box-shadow: -2px 24px 24px -19px #111;
  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  max-width: 1400px;
}

.banner-location-selected__info-card .location-banner-info-card {
  margin-top: 0;
}

.banner-location-selected__info-card[data-visible="false"] {
  height: 0px;
  margin: 0px;
}

.banner-location-selected__info-card[data-visible="false"] .location-banner-info-card {
  visibility: hidden;
  height: 0;
}

.banner-location-selected__info-card[data-visible="true"] {
  height: 350px;
  margin: 0px;
}

.banner-location-selected__info-card[data-visible="true"] .location-banner-info-card {
  visibility: visible;
  height: unset;
}

.banner-location-select__results {
  overflow-y: hidden;
  max-height: 230px;
  /* approximate max height */
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  box-shadow: -2px 24px 24px -19px #111;
  position: absolute;
  top: 3rem;
  z-index: 2;
  width: 100vw;
  background-color: #ffffff;
  padding-top: 3.5rem;
  max-width: 1400px;
}

.banner-location-select__results[data-visible="false"] {
  height: 0;
}

.banner-location-select__results[data-visible="true"] {
  height: 230px;
}

@media (min-width: 768px) {
  .banner-location-selector {
    mask-size: 375.4px;
    min-height: 2.813rem;
    margin-top: -43px;
  }
  .banner-location-selector .banner-location-selected {
    justify-content: center;
  }
  .banner-location-selector .banner-location-selected .banner-location-selected__map-link,
  .banner-location-selector .banner-location-selected .banner-location-selected__store-name,
  .banner-location-selector .banner-location-selected .banner-location-selected__info-link {
    margin-top: 10px;
  }
  .banner-location-selector .banner-location-selected .banner-location-selected__store-name {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .location-banner-info-card {
    top: 4rem;
  }
  .banner-location-select__search {
    top: 6rem;
  }
}

article.legal {
  width: 90%;
  min-height: 80vh;
  margin: 0 auto;
  margin-top: 1rem;
}

article.legal h1 {
  text-align: center;
}

article.legal h2 {
  margin-top: .5rem;
}

article.legal p {
  margin: 0 auto;
}

@media (min-width: 768px) {
  article.legal {
    width: 60%;
  }
  article.legal h1 {
    text-align: center;
  }
}

.menu-horizontal {
  width: 95%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  text-align: center;
}

.menu-header {
  text-align: center;
  margin-top: 2rem;
}

.menu-item {
  margin-bottom: 2rem;
}

.menu-item img {
  width: 100%;
}

.menu-item .menu-item__name {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.menu-item .menu-item-details input[type="button"] {
  display: block;
  margin: 0 auto;
}

.menu-item .menu-item-details__card {
  width: 80%;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: hidden;
}

.menu-item .menu-item-details__card[data-visible="false"] {
  animation: details-card-hide 250ms ease-in-out forwards;
}

.menu-item .menu-item-details__card[data-visible="true"] {
  animation: details-card-reveal 250ms ease-in-out forwards;
}

.menu-item .menu-item-details__card .card-section {
  margin-top: 2rem;
  clear: both;
}

.menu-item .menu-item-details__card .card-section .card-section__header span {
  display: inline-block;
}

.menu-item .menu-item-details__card .card-section .card-section__header i {
  float: right;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-facts-list {
  background-color: #F4F4F4;
  display: flex;
  justify-content: space-evenly;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-fact {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-fact span:first-child {
  color: #f26c31;
  font-weight: bold;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details {
  margin-top: 1rem;
  width: 50%;
  font-size: 0.625rem;
  /* 10/16 */
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.left {
  float: left;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.right {
  float: right;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.right:after {
  clear: both;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.left span, .menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.right span {
  display: inline-block;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.left span:first-child, .menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.right span:first-child {
  color: #963821;
  text-align: left;
  width: 60%;
}

.menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.left span:last-child, .menu-item .menu-item-details__card .card-section.nutrition .nutrition-details.right span:last-child {
  width: 30%;
}

.menu-item .menu-item-details__card .card-section.allergens .card-section__body {
  color: #963821;
}

@keyframes details-card-reveal {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 359px;
    opacity: 1;
  }
}

@keyframes details-card-hide {
  from {
    height: 359px;
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.menu-item-small {
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
}

.menu-item-small a {
  text-decoration: none;
  color: #4f2c1d;
}

.menu-item-small a img {
  margin-bottom: 1rem;
  max-width: 8.5rem;
}

.menu-item-small .menu-item-small__header {
  text-align: center;
}

.menu-item-small .menu-item-small__header .accent-small {
  display: block;
  margin-bottom: -0.85rem;
  margin-top: -0.8125rem;
}

@media (min-width: 768px) {
  .menu-item-small a img {
    margin: 0 auto;
    margin-bottom: 2rem;
    display: block;
    max-width: 8.5rem;
  }
  .menu-item-small .menu-item-small__header .accent-small {
    margin-top: -1.8125rem;
  }
}

.banner-roadside {
  background-color: #f26c31;
}

.banner-roadside .banner-roadside__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 43px;
  padding-bottom: 42px;
}

.banner-roadside .banner-roadside__image img:first-child {
  margin-bottom: 21px;
  width: 60%;
}

.banner-roadside .banner-roadside__image img:last-child {
  width: 60%;
}

.banner-roadside .banner-roadside__copy {
  display: none;
}

@media (min-width: 768px) {
  .banner-roadside .banner-roadside__image {
    padding-top: 22px;
    padding-bottom: 22px;
    flex-direction: row-reverse;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }
  .banner-roadside .banner-roadside__image img:first-child {
    width: 50%;
    margin-left: 1rem;
  }
  .banner-roadside .banner-roadside__image img:last-child {
    width: 50%;
  }
  .banner-roadside .banner-roadside__copy {
    display: block;
    background-color: #000000;
    color: #ffffff;
    padding: 114px 0 114px 0;
  }
  .banner-roadside .banner-roadside__copy p {
    width: 70%;
    margin: 0 auto;
    line-height: 50px;
  }
  .banner-roadside .banner-roadside__copy p:first-child {
    margin-bottom: 1rem;
  }
  .banner-roadside .banner-roadside__copy p:last-child {
    text-align: right;
  }
  .banner-roadside .banner-roadside__copy .special-text {
    color: #f26c31;
    text-transform: uppercase;
  }
}

.social-horizontal {
  margin-top: 2rem;
}

.social-horizontal .h1 {
  text-align: center;
  margin-bottom: 25px;
}

.social-horizontal .horizontal-scroller__item:first-of-type {
  margin-left: 30px;
}

.social-horizontal .horizontal-scroller__item img {
  margin-right: 30px;
}

@media (min-width: 768px) {
  .social-horizontal .horizontal-scroller .horizontal-scroller__item:first-of-type {
    margin-left: 1.5%;
  }
  .social-horizontal .horizontal-scroller .horizontal-scroller__item:last-of-type img {
    margin-right: 0px;
  }
  .social-horizontal .horizontal-scroller .horizontal-scroller__item img {
    margin-bottom: 30px;
    width: 16rem;
  }
}

/*@include media(">=desktop"){
  .social-horizontal {
    .horizontal-scroller{
      justify-content: space-evenly;
      .horizontal-scroller__item {
        flex-basis: 15%;
        &:first-of-type {
          margin-left: 10px;
        }
        img {        
          margin-right: 0px;
        }
      }
    }
  }
}*/
.inside-the-mug {
  position: relative;
}

.inside-the-mug .h1 {
  text-align: center;
  margin-top: 45px;
  margin-bottom: 25px;
}

.inside-the-mug a {
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1.563rem;
}

@media (min-width: 768px) {
  .inside-the-mug .inside-the-mug__posts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.post-teaser {
  text-align: center;
}

.post-teaser .post-teaser__wrapper {
  margin-bottom: 2rem;
  display: inline-block;
}

.post-teaser .post-teaser__wrapper .blog-img {
  width: 250px;
  height: auto;
}

.post-teaser .post-teaser__wrapper .post-teaser__card {
  border: 0.25rem solid;
  width: 16.875rem;
  height: 10.9375rem;
  background-color: #ffffff;
  margin-top: -9rem;
  position: relative;
  margin-left: 8rem;
  padding-left: 1.125rem;
  padding-top: 1rem;
  text-align: left;
  border-color: #f26c31;
  display: flex;
  flex-direction: column;
}

.post-teaser .post-teaser__wrapper .post-teaser__card .post-teaser__card-category a {
  width: 100%;
  font-size: 0.625rem;
  color: #f26c31;
  text-transform: uppercase;
}

.post-teaser .post-teaser__wrapper .post-teaser__card a {
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: #f26c31;
  text-transform: uppercase;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .post-teaser {
    flex-basis: 25%;
  }
}

@media (min-width: 953px) and (max-width: 1199px) {
  .post-teaser .blog-img {
    width: 7.9375rem;
  }
  .post-teaser .post-teaser__card {
    width: 11.875rem;
    height: 11.9375rem;
  }
}

.tout-dav {
  background-color: #4f2c1d;
  display: flex;
  justify-content: center;
  padding: 1.438rem;
  align-items: center;
}

.tout-dav .tout-dav__header {
  color: #ffffff;
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  margin-right: 1rem;
}

.tout-dav .tout-dav__logo img {
  width: 6.938rem;
}

.mug-club-form {
  padding: 1.563rem;
  margin-bottom: 1.563rem;
  background-color: #f26c31;
}

.mug-club-form h1 {
  text-align: center;
  color: #ffffff;
}

.mug-club-form .header__reasons {
  display: flex;
  text-align: center;
  justify-content: space-around;
  max-width: 400px;
  margin: 0 auto;
}

.mug-club-form .header__reasons img, .mug-club-form .header__reasons p {
  width: 7.5rem;
}

.mug-club-form .header__reasons p {
  color: #ffffff;
}

.mug-club-form .aw-form {
  width: 325px;
  margin: 0 auto;
}

.mug-club-form .aw-form .section-label {
  color: #ffffff;
  font-size: 20px;
  margin-left: 1.563REM;
  margin-bottom: 10PX;
}

.mug-club-form .birthday-field {
  display: flex;
}

.mug-club-form .aw-field {
  margin: 0 auto;
  margin-bottom: 1.25rem;
  max-width: 23.563rem;
}

.mug-club-form .aw-field input, .mug-club-form .aw-field select {
  border-color: #4f2c1d;
  background-color: #f26c31;
  color: #ffffff;
  font-size: 1rem;
}

.mug-club-form .aw-field label, .mug-club-form .aw-field ::placeholder {
  color: #ffffff;
  font-size: 1rem;
}

.mug-club-form .aw-field.checkbox.legal {
  text-align: center;
}

.mug-club-form .aw-field.checkbox.legal a {
  color: #ffffff;
  text-decoration: underline;
}

.mug-club-form input[type="button"] {
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .mug-club-form .header__reasons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mug-club-form .header__reasons div:first-child {
    margin-left: -4rem;
    margin-right: 2rem;
  }
  .mug-club-form .header__reasons img, .mug-club-form .header__reasons p {
    width: 14rem;
  }
  .mug-club-form .header__reasons p {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  .mug-club-form .aw-form {
    width: unset;
    margin: unset;
    margin-right: 2rem;
  }
}

.sweepstake, .contact-us {
  margin-top: 2rem;
}

.sweepstake .sweepstake__image img, .sweepstake .contact-us__image img, .contact-us .sweepstake__image img, .contact-us .contact-us__image img {
  display: block;
  width: 100%;
}

.sweepstake .sweepstake__header, .sweepstake .contact-us__header, .contact-us .sweepstake__header, .contact-us .contact-us__header {
  display: none;
}

.sweepstake .aw-form .aw-field, .contact-us .aw-form .aw-field {
  margin: 0 auto;
  margin-bottom: 1.25rem;
  max-width: 23.563rem;
}

.sweepstake .aw-form input[type="button"], .contact-us .aw-form input[type="button"] {
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .sweepstake .sweepstake__header, .sweepstake .contact-us__header, .contact-us .sweepstake__header, .contact-us .contact-us__header {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.location-finder .location-finder__header {
  background-color: #f26c31;
  height: 2.813rem;
  text-align: center;
}

.location-finder .location-finder__header h1 {
  color: #FFFFFF;
  line-height: 2.813rem;
}

.location-finder .location-finder__search {
  margin: 0 auto;
  margin-top: 1.875rem;
  width: 375px;
}

.location-finder .location-finder__search .aw-field.search {
  margin: 0 auto;
}

.location-finder .location-finder__search .aw-field.search input {
  border-color: #4f2c1d;
  color: #963821;
  font-size: 1rem;
}

.location-finder .location-finder__search .aw-field.search label, .location-finder .location-finder__search .aw-field.search ::placeholder {
  color: #963821;
  font-size: 1rem;
}

.location-finder .location-finder__search .aw-field.search i {
  color: #ffffff;
  background-color: #f26c31;
}

.location-finder .location-finder__search .location-finder__filter {
  width: 18.875rem;
  margin: 0 auto;
  margin-top: 1.563rem;
  background-color: #ffffff;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-button {
  border-bottom: solid 1px;
  border-bottom-color: #963821;
  margin-bottom: 16px;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-button i, .location-finder .location-finder__search .location-finder__filter .location-finder__filter-button input {
  color: #963821;
  margin-bottom: 0.5rem;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-button input {
  background-color: transparent;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-options {
  width: 18.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-options .aw-field {
  flex-basis: 50%;
  color: #963821;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-options[data-visible="false"] {
  animation: options-hide 250ms ease-in-out forwards;
}

.location-finder .location-finder__search .location-finder__filter .location-finder__filter-options[data-visible="true"] {
  animation: options-reveal 250ms ease-in-out forwards;
}

.location-finder .location-finder__search .aw-field.switch {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}

.location-finder .location-finder__search .aw-field.switch label {
  width: 9.375rem;
}

.location-finder .location-finder__map {
  text-align: center;
  display: none;
}

.location-finder .location-finder__map[data-visible="false"] {
  display: none;
}

.location-finder .location-finder__map[data-visible="true"] {
  display: block;
}

.location-finder .location-finder__results {
  width: 375px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .location-finder .location-finder__header {
    margin-top: -3rem;
  }
  .location-finder .flex-row {
    flex-direction: row-reverse;
  }
  .location-finder .location-finder__search .aw-field.switch {
    display: none;
  }
  .location-finder .location-finder__map {
    display: block;
  }
  .location-finder .location-finder__map iframe {
    width: 90%;
    height: 500px;
    margin: 0;
    margin-top: 2rem;
  }
  .location-finder .location-finder__map[data-visible="false"] {
    display: block;
  }
  .location-finder .location-finder__map[data-visible="true"] {
    display: block;
  }
  .location-finder .location-finder__results {
    height: 400px;
    overflow: scroll;
  }
}

@keyframes options-reveal {
  from {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  to {
    height: 68px;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes options-hide {
  from {
    height: 68px;
    opacity: 1;
    visibility: visible;
  }
  to {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

section.form-contact {
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;
}

section.form-contact .form-contact__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

section.form-contact .aw-field, section.form-contact input[type='button'] {
  margin-top: 1rem;
}

.location-card, .location-banner-info-card {
  border: solid 3px;
  border-color: #f26c31;
  width: 90%;
  margin: 0 auto;
  margin-top: 1.875rem;
  padding: 1.25rem;
  background-color: #ffffff;
}

.location-card .location-card__restaurant-overview, .location-banner-info-card .location-card__restaurant-overview {
  display: flex;
  flex-direction: row;
}

.location-card .location-card__restaurant-overview .restaurant-overview__image, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__image {
  flex-basis: 30%;
  text-align: center;
}

.location-card .location-card__restaurant-overview .restaurant-overview__image img, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__image img {
  display: block;
  margin: 0 auto;
}

.location-card .location-card__restaurant-overview .restaurant-overview__image div, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__image div {
  color: #f26c31;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address {
  flex-basis: 70%;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__store-name, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__store-name {
  font-size: 1.25rem;
  color: #4f2c1d;
  text-transform: uppercase;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__store-contact, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__store-contact {
  color: #963821;
  margin-bottom: .5rem;
  line-height: 1.2rem;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities {
  margin-bottom: 1rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities img, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities img {
  width: 1rem;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities img[data-available="false"], .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities img[data-available="false"] {
  opacity: 0.1;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity {
  margin-left: 10px;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity span, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity span {
  display: none;
  position: absolute;
  z-index: 1;
  line-height: 20px;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  color: #4f2c1d;
  background: white;
  border: 4px solid white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px 0px;
}

.location-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity span:after, .location-banner-info-card .location-card__restaurant-overview .restaurant-overview__address .restaurant-overview__amenities .ammenity span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #FFFFFF transparent;
  top: -24px;
  left: 0px;
}

.location-card .location-card__buttons, .location-banner-info-card .location-card__buttons {
  display: flex;
  justify-content: space-evenly;
}

.location-card .location-card__buttons a, .location-banner-info-card .location-card__buttons a {
  font-size: 0.875rem;
}

.location-banner-info-card {
  border: none;
  width: 100%;
}

.location-banner-info-card .restaurant-overview__image {
  flex-basis: 30%;
  text-align: center;
}

.location-banner-info-card .restaurant-overview__image img {
  display: block;
  margin: 0 auto;
  width: 6rem;
}

.location-banner-info-card .restaurant-overview__image div {
  display: none;
}

.location-banner-info-card .location-banner-info-card__change {
  text-align: center;
}

.store-current-deal {
  width: 75%;
  text-align: center;
  margin: 0 auto;
}

.store-header {
  margin-bottom: 1.5rem;
  margin-top: -3rem;
}

.store-header .store-header__main {
  color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 3rem;
  text-align: center;
  background-image: url(../assets/bg-secondary-tout.svg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.store-header .store-header__main-wrapper .store-header__details {
  width: 90%;
  margin: 0 auto;
  margin-top: -2rem;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities {
  background-color: #ffffff;
  padding: 1.063rem 2.375rem 1.063rem 2.375rem;
  display: flex;
  justify-content: space-around;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities img {
  display: block;
  height: 1.875rem;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities img[data-available="false"] {
  opacity: 0.1;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities .ammenity span {
  display: none;
  position: absolute;
  z-index: 1;
  line-height: 10px;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  color: #4f2c1d;
  background: white;
  border: 4px solid white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px 0px;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities .ammenity span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #FFFFFF transparent;
  top: -24px;
  left: 0px;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-ammenities .ammenity:hover span {
  display: block;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours {
  margin-bottom: 1.5rem;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours .hours__row {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 0 auto;
  justify-content: space-between;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours .hours__row :first-child {
  color: #4f2c1d;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours .hours__row :last-child {
  color: #963821;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours > .hours__row {
  border-top: 1px solid;
  border-color: grey;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-hours > .hours__row:first-child {
  border-top: none;
}

.store-header .store-header__main-wrapper .store-header__details .store-header__details-buttons {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.store-header .store-header__main-wrapper .store-header__current-deal {
  margin-top: 1rem;
}

.store-header .store-header__main-wrapper .store-header__rooty-mobile {
  display: block;
}

.store-header .store-header__map-wrapper {
  display: none;
}

.store-header .store-header__map-wrapper iframe {
  display: none;
}

@media (min-width: 768px) {
  .store-header .store-header__main-wrapper {
    max-width: 50%;
    float: left;
  }
  .store-header .store-header__main-wrapper .store-header__rooty-mobile {
    display: none;
  }
  .store-header .store-header__main-wrapper .store-header__social .store-social__header {
    display: none !important;
  }
  .store-header .store-header__map-wrapper {
    display: block;
    margin: 0 auto;
    max-width: 50%;
    float: right;
    margin-top: -2rem;
    z-index: 2;
  }
  .store-header .store-header__map-wrapper iframe {
    width: 100%;
    height: 415px;
    margin: 0;
    display: block;
  }
  .store-header .store-header__map-wrapper .store-header__map-wrapper-rooty {
    margin-top: -2rem;
    margin-left: 9rem;
  }
}

.rooty-speech-bubble {
  width: 80%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.rooty-speech-bubble .rooty-speech-bubble__rooty {
  position: relative;
  min-width: 4.375rem;
}

.rooty-speech-bubble .rooty-speech-bubble__rooty img {
  display: block;
  width: 4.375rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.rooty-speech-bubble .rooty-speech-bubble__speech-bubble p {
  margin-bottom: 0px;
  text-align: left;
  margin-left: 1rem;
}

.rectangle-speech-border {
  position: relative;
  padding: 10px 10px;
  margin: 0 0 1em;
  border: 3px solid #963821;
  text-align: center;
  color: #963821;
  background: #fff;
  /* css3 */
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

/* creates larger curve */
.rectangle-speech-border:before {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -20px;
  left: 7px;
  width: 50px;
  height: 18px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: #963821;
  background: transparent;
  border-bottom-right-radius: 104px 50px;
  display: block;
  /* css3 */
  -webkit-border-bottom-right-radius: 104px 50px;
  -moz-border-radius-bottomright: 104px 50px;
  border-bottom-right-radius: 104px 50px;
  /* reduce the damage in FF3.0 */
  display: block;
}

/* creates smaller curve */
.rectangle-speech-border:after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -20px;
  left: 10px;
  width: 20px;
  height: 18px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: #963821;
  background: transparent;
  border-bottom-right-radius: 23px 30px;
  display: block;
  /* css3 */
  -webkit-border-bottom-right-radius: 23px 30px;
  -moz-border-radius-bottomright: 23px 30px;
  border-bottom-right-radius: 23px 30px;
  /* reduce the damage in FF3.0 */
  display: block;
}

/* creates a small circle to produce a rounded point where the two curves meet */
.rectangle-speech-border > :first-child:before {
  /*content:"";
  position:absolute;
  bottom:-40px;
  left:50px;
  width:3px;
  height:3px;
  background:#5a8f00;*/
  /* css3 */
  /*-webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;*/
}

/* creates a white rectangle to cover part of the oval border*/
.rectangle-speech-border > :first-child:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 30px;
  width: 24px;
  height: 15px;
  background: #fff;
}

.store-social .store-social__header {
  text-align: center;
}

.store-social .store-social__icons {
  text-align: center;
}

.store-social .store-social__icons img {
  display: inline-block;
  margin: 0px 15px 15px 15px;
  width: 50px;
}

.location-result-card {
  background-color: #f26c31;
  font-size: 0.625rem;
  color: #ffffff;
  padding: 0.625rem 1.925rem 0.625rem 1.925rem;
  margin-left: 15px;
  margin-top: 1.688rem;
  margin-bottom: 1.688rem;
  border-radius: 5px;
  background-image: url(../assets/bg-secondary-tout.svg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.location-result-card .location-result-card__distance {
  margin-bottom: 1.625rem;
}

.location-result-card .location-result-card__distance i {
  margin-right: 0.5rem;
}

.location-result-card .location-result-card__address {
  text-align: center;
}

.blog-card {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  margin-bottom: 2rem;
}

.blog-card img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.blog-card .blog-card__body {
  margin: 0 auto;
  width: 90%;
}

.blog-card .blog-card__body span, .blog-card .blog-card__body a:last-child {
  display: inline-block;
  width: 49%;
}

.blog-card .blog-card__body span {
  font-size: 0.625rem;
  text-transform: uppercase;
}

.blog-card .blog-card__body a:first-child {
  font-size: 0.625rem;
  color: #f26c31;
  text-transform: uppercase;
}

.blog-card .blog-card__body a:last-child {
  text-align: right;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: #f26c31;
  text-transform: uppercase;
  text-decoration: underline;
}

.blog-category-horizontal {
  text-align: center;
  background-color: #f26c31;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.blog-category-horizontal .menu-item-small a img {
  min-width: 10.5rem;
  max-width: 10.5rem;
  margin-bottom: 1rem;
}

.blog-latest {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.blog-latest h1 {
  width: 85%;
  margin: 0 auto;
}

.blog-latest .blog-card {
  width: 85%;
}

.blog-post-list h1 {
  width: 85%;
  margin: 0 auto;
}

.blog-post-list .blog-card p {
  display: none;
}

.blog-post-list a:last-child {
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1.563rem;
}

.header-blog-category {
  color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 3rem;
  text-align: center;
  background-color: #f26c31;
}

.page-blog-category .blog-latest {
  margin-top: -5rem;
}

.page-blog-category .blog-latest h1 {
  display: none;
}

.page-blog-category .blog-post-list h1 {
  display: none;
}

img.blog-post-featured {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

article.blog-post {
  width: 50%;
  margin: 0 auto;
}

article.blog-post h1 {
  text-align: center;
}

article.blog-post h2.subhead {
  text-align: center;
}

article.blog-post img {
  margin-bottom: 1rem;
  width: 100%;
}

/* https://codepen.io/segg/pen/oPbVeP */
.aw-pagination {
  margin: 2rem auto 1rem;
  text-align: center;
  flex-flow: row wrap;
  justify-content: center;
  display: flex;
  font-size: 1.6rem;
}

.aw-pagination > a {
  background-color: #f26c31;
  color: white;
  height: 2.5rem;
  line-height: 2.5rem;
  outline: none;
  text-decoration: none;
  transition: all .2s;
  width: 2.5rem;
}

.aw-pagination > .first + .current {
  margin-left: .4rem;
}

.aw-pagination > .current + .last {
  margin-left: .4rem;
}

.aw-pagination > a.Icon {
  margin: 0 .4rem;
  stroke: currentColor;
}

.aw-pagination > a:hover,
.aw-pagination > a:focus,
.aw-pagination > a:active,
.aw-pagination > form > input:focus,
.aw-pagination > form > input:hover {
  background-color: #963821;
  box-shadow: 0 0.3rem 0.3rem -0.2rem #f26c31;
}

.aw-pagination > form > input {
  -moz-appearance: textfield;
  background-color: #963821;
  border: none;
  color: inherit;
  height: 2.5rem;
  line-height: 2.5;
  outline: none;
  text-align: inherit;
  width: 2.5rem;
  color: white;
}

.aw-pagination > form > input::-webkit-inner-spin-button,
.aw-pagination > form > input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.aw-pagination > form > label {
  position: absolute;
  right: 110vw;
}

.aw-pagination > small {
  margin: 1rem auto;
  min-width: 100%;
}

.region-footer {
  background-image: url(../assets/bg-footer.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.aw-footer {
  font-family: "Century Gothic Bold";
  color: #ffffff;
}

.aw-footer a {
  color: #ffffff;
  display: block;
  margin-top: 1.5rem;
  cursor: pointer;
}

.aw-footer a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.aw-footer .site-links, .aw-footer .external-links {
  display: flex;
  justify-content: space-evenly;
}

.aw-footer .external-links {
  font-size: 0.625rem;
}

.aw-footer .social-links {
  display: flex;
  justify-content: center;
}

.aw-footer .social-links a {
  display: inline-block;
  margin-right: 1.5rem;
}

.aw-footer .social-links a img {
  height: 2.25rem;
}

.aw-footer .copyright {
  margin: 0 auto;
  font-size: 0.625rem;
  text-align: center;
  padding-bottom: 1rem;
  margin-top: 1rem;
  width: 90%;
}

@media (min-width: 768px) {
  .aw-footer a {
    display: inline-block;
    margin-left: .5rem;
  }
  .aw-footer .site-links, .aw-footer .external-links {
    justify-content: center;
  }
}

main {
  overflow: hidden;
}

/* Some Extra Classes */
.sg-header {
  width: 100%;
  background-color: pink;
  color: blue;
  font-size: 1.875rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.sg-buttons .aw-field, .sg-buttons input[type="button"] {
  display: block;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
