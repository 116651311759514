.blog-latest {
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    h1 {
        width: 85%;
        margin: 0 auto;
    }
    .blog-card {
        width: 85%;
    }
}