img.blog-post-featured {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

article.blog-post {
    width: 50%;
    margin: 0 auto;

    h1 {
        text-align: center;
    }
    
    h2.subhead {
        text-align: center;
    }

    img {
        margin-bottom: 1rem;
        width: 100%;
    }
}